import { Spin, Row, Table, Col, Input, Button, Tooltip, Tag, Collapse, Form, Select } from 'antd';
import { SearchOutlined, EditOutlined, FileTextOutlined, CloseCircleOutlined, EyeOutlined, UnorderedListOutlined, ContainerOutlined, HourglassOutlined, InboxOutlined, QuestionCircleOutlined, PushpinOutlined, FileSearchOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getStatus, getStatusByCountry, searchApplicatoin as searchApplication, startReviewApplication } from '../../apis/application';
import { showErrorMessage } from '../../components/message';
import Widget from '../../components/widget/Widget';
import { ApplicationStatus } from '../../constants';
import { ApplicationsContext } from '../../contexts/ApplicationsContext';
import './Applications.css';
import ReloadBtn from '../../components/reloadBtn/ReloadBtn';
import CustomTable from '../../components/CustomTable/CustomTable';
import { scrollToPos } from '../../utils/helper';
import CountrySelect from '../../components/countrySelect/CountrySelect';
import { AuthContext } from '../../contexts/AuthContext';
import { AuthAction } from "../../reducers/authReducer";
import { getUserProfile as getUserProfileApi } from '../../apis/auth';
import userEvent from '@testing-library/user-event';

const Applications = () => {

    const [applicationsState, appDispatch] = useContext(ApplicationsContext);
    const [authState, authDispatch] = useContext(AuthContext);
    let navigate = useNavigate();

    const { pageSize, currentPage, totalPages, totalItems, items, status, text, country } = applicationsState;

    const [loading, setLoading] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [inAction, setInAction] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [userProfileReady, setUserProfileReady] = useState(false);
    const [userFrom, setUserFrom] = useState(null);
    const { user } = authState;

    const columns = [
        {
            title: 'Status',
            dataIndex: 'statusCh',
            key: 'statusCh',
            width: 50,
            render: (t, r) => {
                switch (r.statusCh) {
                    case ApplicationStatus.RECIEVE:
                        return (
                            <Tag className='statusTag recieveTag'>
                                <InboxOutlined />
                            </Tag>
                        );
                    case ApplicationStatus.UNDER_REVIEW:
                        return (
                            <Tag className='statusTag reviewTag'>
                                <EyeOutlined />
                            </Tag>
                        );
                    case ApplicationStatus.ON_HOLD:
                        return (
                            <Tag className='statusTag holdTag'>
                                <HourglassOutlined />
                            </Tag>);
                    case ApplicationStatus.REJECTED:
                        return (
                            <Tag className='statusTag rejectTag'>
                                <CloseCircleOutlined />
                            </Tag>);
                    case ApplicationStatus.COMPLETE:
                        return (<Tag className='statusTag completeTag'> <ContainerOutlined /></Tag>);
                    default:
                        return (<Tag className='statusTag unknownTag'> <QuestionCircleOutlined /></Tag>);
                }
            }
        },
        {
            title: 'Attention',
            dataIndex: 'attentionCh',
            key: 'attentionCh',
            width: 300,
        },
        {
            title: 'Shipper Name',
            dataIndex: 'shipperNameCh',
            key: 'shipperNameCh',
            width: 300,
        },
        {
            title: 'Shipper Code',
            dataIndex: 'shipperCh',
            key: 'shipperCh',
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
        },
        
        {
            title: 'Date Received',
            dataIndex: 'createdDt',
            key: 'createdDt',
            width: 250,
            render: ((date:string) => getFullDate(date))
        },
        {
            title: '',
            dataIndex: 'statusCh',
            key: 'statusCh',
            width: 150,
            render: (text, record) => {
                switch (text) {
                    case ApplicationStatus.RECIEVE:
                        return (
                            <Tooltip title='Start Review'>
                                <Button className='tableBtn' onClick={() => navigate(`/dashboard/application/${record.id}/view`)} icon={<FileSearchOutlined />} />
                            </Tooltip>
                        )
                    case ApplicationStatus.UNDER_REVIEW:
                    case ApplicationStatus.ON_HOLD:
                        return (
                            <>
                                <Tooltip title='edit'>
                                    <Button className='tableBtn' icon={<EditOutlined />} onClick={() => editAppOnClick(record)} />
                                </Tooltip>
                            </>
                        )
                    case ApplicationStatus.REJECTED:
                    case ApplicationStatus.COMPLETE:
                        return (
                            <Tooltip title='view'>
                                <Button className='tableBtn' icon={<UnorderedListOutlined />} onClick={() => navigate(`/dashboard/application/${record.id}/view`)} />
                            </Tooltip>
                        )
                }
            }
        }
    ]
    const getFullDate = (date: string): string => {
        const dateAndTime = date.split('T');
      
        return dateAndTime[0].split('-').join('-');
    }
    const selectCountry = () => {
        if(userFrom == 'HQ'){
            return(
            <Form.Item label='Country' wrapperCol={{ span: 24 }}>
                <CountrySelect value={country} onChange={countryOnChange} />
            </Form.Item>)
        }
    }
    const getUserProfile = () => {
        setLoading(true);
        getUserProfileApi()
          .then(res => {
            if (res.status === 200 && res.data.code === 10000) {
                authDispatch({ type: AuthAction.SET_USER_PROFILE, payload: res.data.payload });
                setUserFrom(res.data.payload.country.trim());
            } else {
                logoutHandler();
            }
          })
          .catch(err => {
            console.log(err);
            logoutHandler();
          })
          .finally(() => {                         
            setUserProfileReady(true); 
          })
    }
    useEffect(() => {
        getUserProfile();
    }, []);
    useEffect(() => {
        if(userProfileReady){
            getApplications();
            getApplicationStatus();
        }
    }, [userProfileReady]);

    const getApplications = () => {
        if(user.country.trim() != "HQ"){
            searchApplications(pageSize, currentPage, status, text, user.country);
        }else{
            searchApplications(pageSize, currentPage, status, text, null)
        }
    }

    const searchApplications = (pageSize, pageNumber, status, text, country) => {
        setLoading(true);
        searchApplication({ pageSize: pageSize, pageNumber: pageNumber, status: status, text: text, country: country})
            .then(res => {
                if (res.status === 200 && res.data) {
                    appDispatch({
                        type: 'UPDATE_LIST', payload: {
                            pageSize: res.data.payload.pageSize,
                            totalPages: res.data.payload.totalPages,
                            currentPage: res.data.payload.currentPage,
                            totalItems: res.data.payload.totalCount,
                            items: res.data.payload.items,
                        }
                    });
                } else {
                    appDispatch({
                        type: 'UPDATE_LIST', payload: {
                            pageSize: 10,
                            totalPages: 1,
                            currentPage: 1,
                            totalItems: 1,
                            items: [],
                        }
                    });
                }
            })
            .catch(err => {
                console.error(err);
                appDispatch({
                    type: 'UPDATE_LIST', payload: {
                        pageSize: 10,
                        totalPages: 1,
                        currentPage: 1,
                        totalItems: 1,
                        items: [],
                    }
                });
                // showErrorMessage('Fail to get applications');
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const getApplicationStatus = () => {
        setLoadingStatus(true);
        if (userFrom == 'HQ'){
            getStatus()
                .then(res => {
                    if (res.status === 200 && res.data) {
                        setStatuses(res.data.payload);
                    }
                })
                .catch(err => {
                    console.error(err);
                    // showErrorMessage('Fail to get application status');
                })
                .finally(() => {
                    setLoadingStatus(false);
                })
        }else{
            getStatusByCountry(userFrom)
                .then(res => {
                    if (res.status === 200 && res.data) {
                        setStatuses(res.data.payload);
                    }
                })
                .catch(err => {
                    console.error(err);
                    // showErrorMessage('Fail to get application status');
                })
                .finally(() => {
                    setLoadingStatus(false);
                })
        }
    }

    const logoutHandler = () => {
        
        authDispatch({ type: 'LOGOUT' });
    }
    /**
     * Start review application
     */
    const startReviewOnClick = (application) => {
        let confirmAction = window.confirm(`Are you sure to review this application [${application.applicationCodeCh}]?`);
        if (confirmAction) {
            setInAction(true);
            startReviewApplication(application.id)
                .then(res => {
                    if (res.status === 200 && res.data.code === 10000) {
                        navigate(`/dashboard/application/${application.id}/view`);
                    } else {
                        console.debug('Fail to review application. Code: ' + res.data.code, res.data);
                        showErrorMessage('Fail to review application. Code: ' + res.data.code);
                    }
                })
                .catch(err => {
                    console.debug('Having issue to review application', err);
                    showErrorMessage('Having issue to review application.');
                })
                .finally(() => {
                    setInAction(false);
                })
        } else {
            alert("Action canceled");
        }
    }

    const editAppOnClick = (application) => {
        if (application && application.id) {
            navigate(`/dashboard/application/${application.id}/edit`)
        } else {
            console.debug('edit application has no id');
        }
    }

    const pageOnChange = (number, size) => {
        searchApplications(size, number, status, text, applicationsState.country);
    }

    const clickStatus = (value) => {
        appDispatch({ type: 'CHANGE_STATUS', payload: value });

        searchApplications(pageSize, 1, value, text, userFrom);
    }

    const getStatusCount = (value) => {
        var stat = statuses.filter(s => s.status === value)[0];
        if (stat) {
            return stat.count;
        } else {
            return 0;
        }
    }

    const searchBoxOnChange = (e) => {
        appDispatch({ type: 'CHANGE_SEARCH_TEXT', payload: e.target.value });
    }

    const countryOnChange = (v) => {
        appDispatch({ type: 'CHANGE_SEARCH_COUNTRY', payload: v });
    }

    const searchOnClick = () => {
        if(user.country.trim() != 'HQ'){
            searchApplications(pageSize, 1, status, text, user.country);
        }else{
            searchApplications(pageSize, 1, status, text, applicationsState.country);
        }
    }


    return (
        <div className='applicationsPageWrapper'>
            <Spin spinning={loading || loadingStatus || inAction}>
                <div className="applicationsTop disableSelect">
                    <Widget>
                        <Row className='statusRow' style={{ marginTop: '10px' }} justify='space-around'>
                            <Col className='applicationsTitleHeader' onClick={() => clickStatus('')}>
                                <FileTextOutlined className='applicationsTitleIcon' />
                                <div className="applicationsTitle ">Applications</div>
                            </Col>
                            <Col>
                                <p className='statusNumber'>{getStatusCount(ApplicationStatus.RECIEVE)}</p>
                                <Widget className='statusWidget receive '><div className='statusWidgetName' onClick={() => clickStatus(ApplicationStatus.RECIEVE)}>Received</div></Widget>
                            </Col>
                            <Col>
                                <p className='statusNumber'>{getStatusCount(ApplicationStatus.UNDER_REVIEW)}</p>
                                <Widget className='statusWidget under_review '><div className='statusWidgetName' onClick={() => clickStatus(ApplicationStatus.UNDER_REVIEW)}>Under Review</div></Widget>
                            </Col>
                            <Col>
                                <p className='statusNumber'>{getStatusCount(ApplicationStatus.ON_HOLD)}</p>
                                <Widget className='statusWidget on_hold ' ><div className='statusWidgetName' onClick={() => clickStatus(ApplicationStatus.ON_HOLD)}>On Hold</div></Widget>
                            </Col>
                            <Col>
                                <p className='statusNumber'>{getStatusCount(ApplicationStatus.COMPLETE)}</p>
                                <Widget className='statusWidget complete ' ><div className='statusWidgetName' onClick={() => clickStatus(ApplicationStatus.COMPLETE)}>Complete</div></Widget>
                            </Col>
                            <Col>
                                <p className='statusNumber'>{getStatusCount(ApplicationStatus.REJECTED)}</p>
                                <Widget className='statusWidget rejected ' ><div className='statusWidgetName' onClick={() => clickStatus(ApplicationStatus.REJECTED)}>Rejected</div></Widget>
                            </Col>
                        </Row>
                    </Widget>
                </div>
                <Row className="widgetRow" gutter={[8, 8]} style={{ marginTop: '10px' }}>
                    <Widget span={24}>
                        <div className="tableWidgetWrapper">
                            <div className="tableWidgetTop">
                            </div>
                            <div className="tabelWidgetContent" >
                                <Row>
                                    <Col span={4}>
                                        <Collapse defaultActiveKey={1} ghost>
                                            <Collapse.Panel header='Filter' key={1}>
                                                <Form.Item label='Search Text' wrapperCol={{ span: 24 }}>
                                                    <Input value={text} type='text' placeholder='Search Text' onChange={searchBoxOnChange} />
                                                </Form.Item>
                                                {selectCountry()}
                                                <Row justify='end'>
                                                    <Col>
                                                        <ReloadBtn loading={loading} onClick={searchOnClick} />
                                                    </Col>
                                                </Row>
                                            </Collapse.Panel>
                                        </Collapse>
                                    </Col>
                                    <Col span={20}>
                                        {/* <Table pagination={{ position: ['topRight'], size: 'small', pageSize: pageSize, current: currentPage, total: totalItems, onChange: pageOnChange, showSizeChanger: false }} dataSource={items} columns={columns} /> */}

                                        <CustomTable pageSize={pageSize} currentPage={currentPage} totalCount={totalItems} pageOnChange={pageOnChange} items={items} columns={columns} />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Widget>
                </Row>
            </Spin>
        </div>
    )
}

export default Applications;