import { Col, Skeleton } from "antd";
import React from "react";

const style = {
  padding: "8px 10px",
  borderRadius: "10px",
  WebkitBoxShadow: "0px 0px 8px -4px #000000",
  boxShadow: "0px 0px 8px -4px #000000",
  height: '100%'
};

const Widget = (props) => {

  const { span, children, loading } = props;

  if (loading) {
    return (
      <Col {...props} className="widget">
        <div className="widgetContent" style={style}>
          <Skeleton active/>
        </div>
      </Col>
    )
  }

  return (
    <Col {...props} className='widget'>
      <div className={`widgetContent ${props.className}`} style={style}>
        {children}
      </div>
    </Col>
  );
};

export default Widget;
