import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import React from 'react';

const TooltipLabel = ({ label, tooltip }) => {
    return (
        <div className='tooltipLabel'>
            {label || ''}
            {tooltip ? <Tooltip title={tooltip}> <QuestionCircleOutlined /></Tooltip> : <></>}
        </div>
    )
}

export default TooltipLabel;