import axios from "axios";
import { getApiHeaderNotTime } from "../utils/getApiHeaders";

export function getGroups() {
    const options = {
        method: 'GET',
        url: process.env.REACT_APP_URL + `/group/all`,
        headers: getApiHeaderNotTime(),
    }

    return axios(options)
}

export function getGroupOptions() {
    const options = {
        method: 'GET',
        url: process.env.REACT_APP_URL + `/group/options`,
        headers: getApiHeaderNotTime(),
    }

    return axios(options)
}

export function getGroupById(groupId) {
    const options = {
        method: 'GET',
        url: process.env.REACT_APP_URL + `/group/${groupId}`,
        headers: getApiHeaderNotTime(),
    }

    return axios(options)
}


export function addGroup(form) {
    const options = {
        method: 'POST',
        url: process.env.REACT_APP_URL + `/group/create`,
        headers: getApiHeaderNotTime(),
        data: JSON.stringify({
            groupName: form.name,
            permissions: form.permissions,
            userIds: form.userIds
        })
    }

    return axios(options)
}

export function updateGroup(groupId, form) {
    const options = {
        method: 'PUT',
        url: process.env.REACT_APP_URL + `/group/${groupId}/update`,
        headers: getApiHeaderNotTime(),
        data: JSON.stringify({
            name: form.name,
            permissions: form.permissions
        })
    }

    return axios(options)
}

export function addUserToGroup(groupId, userId) {
    const options = {
        method: 'PUT',
        url: process.env.REACT_APP_URL + `/group/${groupId}/adduser`,
        headers: getApiHeaderNotTime(),
        data: JSON.stringify({
            userId: userId
        })
    }

    return axios(options)
}

export function removeUserFromGroup(groupId, userId) {
    const options = {
        method: 'PUT',
        url: process.env.REACT_APP_URL + `/group/${groupId}/removeuser`,
        headers: getApiHeaderNotTime(),
        data: JSON.stringify({
            userId: userId
        })
    }

    return axios(options)
}

export function removeGroup(groupId) {
    const options = {
        method: 'DELETE',
        url: process.env.REACT_APP_URL + `/group/${groupId}/delete`,
        headers: getApiHeaderNotTime(),
    }

    return axios(options)
}