import { Alert, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './AccountActive.css';
import { active } from '../../apis/auth';
import { showErrorMessage } from '../../components/message';

const ActiveStatus = {
    WAIT_FOR_ACTIVE: 0,
    ACTIVED: 1,
    FAIL_TO_ACTIVE: 2
}

const AccountActive = () => {

    const { code } = useParams();
    const [activating, setActivating] = useState(false);
    const [activeStatus, setActiveStatus] = useState(ActiveStatus.WAIT_FOR_ACTIVE);

    let navigate = useNavigate();

    useEffect(() => {
        activateAccount();
    }, [])

    const activateAccount = () => {
        setActivating(true);
        setTimeout(() => {
            active(code)
                .then(res => {
                    if (res.status === 200 && res.status.code === 10000) {
                        setActiveStatus(ActiveStatus.ACTIVED);
                    } else {
                        showErrorMessage('Fail to activate your account');
                        setActiveStatus(ActiveStatus.FAIL_TO_ACTIVE);
                    }
                })
                .catch(err => {
                    showErrorMessage('Having issue to activate your account');
                    setActiveStatus(ActiveStatus.FAIL_TO_ACTIVE);
                })
                .finally(() => {
                    setActivating(false);
                })
        }, 5000);
    }

    const renderIcon = () => {
        if (activating) {
            return <LoadingOutlined />
        } else {
            <InfoCircleOutlined />
        }
    }

    const renderContent = () => {

        console.log('active code', code);

        if (!code) {
            return (
                <Alert
                    message="Error"
                    description="We couldn't get your active code"
                    type="error"
                    showIcon
                />
            )
        } else {
            if (activating) {
                return (
                    <div>
                        <Row justify='center'>
                            <Spin size='large' tip='Activating Your Account' />
                        </Row>
                    </div>
                )
            } else {
                switch (activeStatus) {
                    case ActiveStatus.WAIT_FOR_ACTIVE:
                        return <Alert
                            className='activeMsg'
                            message='Activating Your Account'
                            description='Please wait for activating your account'
                            type='info'
                            showIcon
                            icon={renderIcon()} />;
                    case ActiveStatus.ACTIVED:
                        return <Alert
                            className='activeMsg'
                            message='Your Account is Actived'
                            description={<p>You can <Link to='/auth/login'>login</Link> to YSC Registration.</p>}
                            type='success' />;
                    case ActiveStatus.FAIL_TO_ACTIVE:
                        return (
                            <Alert
                                className='activeMsg'
                                message="Error"
                                description="Active Failed"
                                type="error"
                                showIcon
                            />
                        )
                }
            }
        }
    }

    return (
        <div className='activePageWragger'>
            <Row justify='center'>
                {renderContent()}
            </Row>
        </div>
    )
}

export default AccountActive;