const authReducer = (state, action) => {
    // console.log(action);
    switch (action.type) {
        case AuthAction.LOGIN:

            localStorage.setItem('access_token', action.payload);

            return {
                ...state,
                token: action.payload,
                login: true,
            };
        case AuthAction.LOGOUT:

            localStorage.removeItem('access_token');

            return {
                ...state,
                login: false,
                token: null,
            };
        case AuthAction.SET_USER_PROFILE:
            return {
                ...state,
                user: action.payload
            };
        case AuthAction.UPDATE_PERMISSIONS:
            return {
                ...state,
                permissions: action.payload
            }
        case AuthAction.SET_RESOURCE_TOKEN:

            localStorage.setItem('resource_token', action.payload);

            return {
                ...state,
                resourceToken: action.payload
            }
        default:
            throw new Error();
    }
};

export const AuthAction = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    SET_USER_PROFILE: 'SET_USER_PROFILE',
    UPDATE_PERMISSIONS: 'UPDATE_PERMISSIONS',
    SET_RESOURCE_TOKEN: 'SET_RESOURCE_TOKEN'
}

export default authReducer;