import { logoutProcess } from './authHelper';

export const getApiHeader = () => {
    if (Date.now() <= parseInt(localStorage.getItem('time_expired'))) {
        localStorage.setItem('time_expired', (Date.now() + parseInt(process.env.REACT_APP_TIME_OUT_CLIENT)).toString());
        return getApiHeaderNotTime();
    }
    // logout
    logoutProcess();
    return {
        'content-type': 'application/json',
        Authorization: null,
    }
}

export const getApiHeaderNotTime = (token) => {
    return {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token') || token,
        'Access-Control-Allow-Origin': '*',
    };
};

export const getApiHeaderNotTimeForm = () => {
    return {
        'content-type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'Access-Control-Allow-Origin': '*',
    };
};