import { Table } from 'antd'
import propTypes from 'prop-types'
import React from 'react'

const CustomTable = ({
    paginationPosition,
    paginationSize,
    showTotal,
    pageSize,
    currentPage,
    totalCount,
    pageOnChange,
    showSizeChange,
    items,
    columns,
}) => {
    return (<Table pagination={{ position: paginationPosition, size: paginationSize, pageSize: pageSize, current: currentPage, showTotal: showTotal, total: totalCount, onChange: pageOnChange, showSizeChanger: showSizeChange }} dataSource={items} columns={columns} scroll={{x:400}}/>)
}

CustomTable.propTypes = {
    paginationPosition: propTypes.arrayOf(propTypes.string),
    paginationSize: propTypes.string,
    pageSize: propTypes.number,
    currentPage: propTypes.number,
    totalCount: propTypes.number,
    pageOnChange: propTypes.func,
    showSizeChange: propTypes.bool,
    items: propTypes.arrayOf(propTypes.any),
    columns: propTypes.arrayOf(propTypes.any),
    pageOnChange: propTypes.func,
    showTotal: propTypes.func,
}

CustomTable.defaultProps = {
    paginationSize: 'small',
    paginationPosition: ['topRight'],
    pageSize: 10,
    showSizeChange: false,
    items: [],
    columns: [],
    showTotal: (total) => {
        return `${total} items`;
    }
}

export default CustomTable;