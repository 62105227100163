import { Table } from 'antd';
import React, { useEffect, useContext, useState } from 'react';
import { Row, Col, Collapse, Form, Input, Select, Button, Spin } from 'antd';
import { UserAddOutlined, EditOutlined } from '@ant-design/icons'
import Widget from '../../components/widget/Widget';
import { AppContext } from '../../contexts/AppContext';
import { getValue, renderSelectOptions } from '../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { getUsersByCountry } from '../../apis/user';
import { showErrorMessage, showSuccessMessage } from '../../components/message';
import ReloadBtn from '../../components/reloadBtn/ReloadBtn';
import CustomTable from '../../components/CustomTable/CustomTable';
import { getUserProfile as getUserProfileApi } from '../../apis/auth';
import { AuthContext } from '../../contexts/AuthContext';
import { AuthAction } from "../../reducers/authReducer";

const Users = () => {

    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({
        text: '', country: '',
    });

    const initPagination = {
        pageNumber: 1,
        pageSize: 10,
        total: 1,
    };
    const [pagination, setPagination] = useState(initPagination);
    const [userList, setUserList] = useState([]);
    const [userCountry, setUserCountry] = useState();
    const [loadProfile, setLoadProfile] = useState(false);
    const [appState, appDispatch] = useContext(AppContext);
    const [authState, authDispatch] = useContext(AuthContext);
    let navigate = useNavigate();

    const { options } = appState;

    const tableColumn = [
        {
            title: 'Name',
            dataIndex: 'shipperNameCh',
            key: 'shipperNameCh',
            render: (t, r) => `${r.firstNameCh} ${r.lastNameCh}`
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: '',
            dataIndex: 'isActive',
            key: 'isActive',
            width: 10,
            render: (t, r) => {
                return <Button className='tableBtn' onClick={(e) => navigate(`/dashboard/user/${r.id}`)} icon={<EditOutlined />} />
            }
        }
    ]

    useEffect(() => {
        getUserProfile();
    }, []);
    
    useEffect(() =>{ 
        if(loadProfile){         
            fetchUsers(filter, pagination);
        }
    }, [loadProfile]);

    const getUserProfile = () => {
        
        setLoading(true);
        getUserProfileApi()
          .then(res => {
            if (res.status === 200 && res.data.code === 10000) {
                authDispatch({ type: AuthAction.SET_USER_PROFILE, payload: res.data.payload });
                setUserCountry(res.data.payload.country.trim());
                if(res.data.payload.country.trim() != "HQ"){
                    let newFilter = {...filter};
                    newFilter['country'] = res.data.payload.country.trim();
                    setFilter({...newFilter});
                }
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            setLoadProfile(true);
          })
    }
    const fetchUsers = (filter, pagination) => {
       
        setLoading(true);
        getUsersByCountry({
            ...filter,
            ...pagination
        }).then(res => {
            if (res.status === 200 && res.data.code === 10000) {
                setPagination({
                    ...pagination,
                    pageNumber: res.data.payload.currentPage,
                    pageSize: res.data.payload.pageSize,
                    total: res.data.payload.totalCount
                });
                setUserList(res.data.payload.items);
            } else {
                showErrorMessage('Fail to get user data');
                setUserList([]);
                setPagination(initPagination);
                console.error('Fail to get user data', res.data);
            }
        }).catch(err => {
            showErrorMessage('Error occur');
            setUserList([]);
            setPagination(initPagination);
            console.error('Error occur', err.response);
        }).finally(() => {
            setLoading(false);
        })
    }

    const filterOnChange = (field, value) => {
        let newFilter = { ...filter };
        newFilter[field] = getValue(value).trim();

        setFilter({
            ...newFilter
        });
    }

    const filterBtnOnClick = () => {
        fetchUsers(filter, pagination);
    }

    const pageOnChange = (pageNumber, pageSize) => {
        fetchUsers(filter, { pageNumber: pageNumber, pageSize: pageSize });
    }

    return (
        <div className='usersPageWrapper'>
            <Spin spinning={loading}>
                <div className="usersTop">
                    <Row justify='space-between'>
                        <Col>
                            <div className="usersTitle" style={{ fontSize: '30px', fontWeight: '900' }}>Users</div>
                        </Col>
                        <Col>
                            {/* <Button className='addButton' icon={<UserAddOutlined />} style={{ borderRadius: 10 }} /> */}
                        </Col>
                    </Row>
                </div>
                <Row className="widgetRow" gutter={[8, 8]} style={{ marginTop: '10px' }}>
                    <Widget span={24}>
                        <div className="tableWidgetWrapper">
                            <div className="tableWidgetTop">
                            </div>
                            <div className="tabelWidgetContent" >
                                <Row>
                                    <Col span={4}>
                                        <Collapse defaultActiveKey={['1']} ghost>
                                            <Collapse.Panel header="Filter" key="1">
                                                <Form.Item label='Search' labelCol={{ span: 24 }}>
                                                    <Input placeholder='Search Text' value={filter.name} onChange={(e) => filterOnChange('text', e.target.value)} />
                                                </Form.Item>
                                                <Row justify='end'>
                                                    <Col>
                                                        <ReloadBtn type='primary' onClick={filterBtnOnClick} loading={loading} />
                                                    </Col>
                                                </Row>
                                            </Collapse.Panel>
                                        </Collapse>
                                    </Col>
                                    <Col span={20}>
                                        <CustomTable pageSize={pagination.pageSize} currentPage={pagination.pageNumber} totalCount={pagination.total} pageOnChange={pageOnChange} columns={tableColumn} items={userList} />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Widget>
                </Row>
            </Spin>
        </div>
    )
}

export default Users