import { Affix, Alert, Button, Checkbox, Col, Divider, Form, Input, Modal, Radio, Row, Select, Skeleton, Spin, Switch, Tag, Tooltip, Upload } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import { completeApplication, getApplicationById, getApplicationFileById, holdApplication, rejectApplication, updateApplication, uploadApplicationFile } from '../../apis/application';
import { getUserProfile as getUserProfileApi } from '../../apis/auth';
import { showErrorMessage, showSuccessMessage, showWarningMessage } from '../../components/message';
import Widget from '../../components/widget/Widget';
import { ApplicationStatus } from '../../constants';
import { AppContext } from '../../contexts/AppContext';
import { convertOptionComponent, getFileExt } from '../../utils/helper';
import { PaperClipOutlined, UploadOutlined, CloudDownloadOutlined, UserAddOutlined, PushpinOutlined, SaveOutlined } from '@ant-design/icons';
import FileDownload from 'js-file-download';
import { saveAs } from 'file-saver';

import './ApplicationEdit.css';
import { AuthContext } from '../../contexts/AuthContext';
import { AuthAction } from '../../reducers/authReducer';
import { formValidateMessages, pattern } from '../../utils/patterns';
import CountrySelect from '../../components/countrySelect/CountrySelect';
import PaymentTermsSelect from '../../components/paymentTermSelect/PaymentTermsSelect';
import DestinationCountrySelect from '../../components/destinationCountrySelect/DestinationCountrySelect';

const ApplicationEdit = () => {

    const { id } = useParams()

    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [completing, setCompleting] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [rejecting, setRejecting] = useState(false);
    const [errorMessage, setErrorMessage] = useState({
        title: '',
        message: '',
    });

    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState(null);

    const [application, setApplication] = useState(null);

    const [registerModalVisible, setRegisterModalVisible] = useState(false);
    const [holdModalVisible, setHoldModalVisible] = useState(false);
    const [fileModalVisible, setFileModalVisible] = useState(false);
    const [rejectModalVisible, setRejectModalVisible]= useState(false);
    const [userCountry, setUserCountry] = useState("");
    const [valid, setValid] = useState(false);

    const [appState, appDispatch] = useContext(AppContext);
    const [authState, authDispatch] = useContext(AuthContext);

    const { options } = appState;
    const { resourceToken } = authState;

    let natigate = useNavigate();

    const [form] = Form.useForm();

    useEffect(() => {
        getUserProfile();
        getApplication(id);
    }, [id]);

    const setForm = (application) => {
        form.setFieldsValue({
            ...application
        })
    }
    const logoutHandler = () => {
        authDispatch({ type: 'LOGOUT' });
    }
    const getUserProfile = () => {
        setLoading(true);
        getUserProfileApi()
          .then(res => {
            if (res.status === 200 && res.data.code === 10000) {
                authDispatch({ type: AuthAction.SET_USER_PROFILE, payload: res.data.payload });
                setUserCountry(res.data.payload.country.trim());
            }else{
                logoutHandler();
            }
          })
          .catch(err => {
            console.log(err);
            logoutHandler();
          })
    }
    const getApplication = (id) => {
        setLoading(true);
        getApplicationById(id)
            .then(res => {
                if (res.status === 200 && res.data) {
                    setApplication(res.data.payload);
                    setForm(res.data.payload);

                } else {
                    setApplication(null);
                }
            })
            .catch(err => {
                console.error(err);
                setApplication(null);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const downloadApplicationFile = () => {
        setDownloading(true);
        getApplicationFileById(application.id)
            .then(res => {
                console.log('download file', res.data);
                var blob = new Blob([res.data], { type: res.headers['content-type:'] });
                saveAs(blob, application.fileNameCh);
            })
            .catch(err => {
                showErrorMessage('Having issue to download application file');
            })
            .finally(() => {
                setDownloading(false);
            })
    }

    const renderServiceOptions = () => {
        return convertOptionComponent(options.service);
    }

    const renderBranchOptions = () => {
        return convertOptionComponent(options.branch);
    }

    const renderPolOptions = () => {
        return convertOptionComponent(options.portOfLoading);
    }

    const renderWeightOptions = () => {
        return convertOptionComponent(options.weight);
    }

    const renderLengthOptions = () => {
        return convertOptionComponent(options.length);
    }

    const renderCurrencyOptions = () => {
        return convertOptionComponent(options.currency);
    }

    const renderPaytermOptions = () => {
        return convertOptionComponent(options.paymentTerm)
    }

    const renderCountryOptions = () => {
        return convertOptionComponent(options.country);
    }

    const renderChannelOptions = () => {
        return convertOptionComponent(options.channel);
    }

    const renderPlatformOptions = () => {
        return convertOptionComponent(options.platform)
    }

    const renderErrorMessage = () => {
        if (errorMessage && errorMessage?.title) {
            return (
                <Alert
                    message={errorMessage?.title}
                    description={errorMessage?.message}
                    type="error"
                    showIcon
                />
            )
        }
    }

    const renderPage = () => {
        if (!application) {
            if (loading) {
                return (
                    <Skeleton />
                );
            }
            return (
                <>
                    <h2>Application Not Found</h2>
                    <Skeleton />
                </>
            )
        } else {
            if(userCountry !== application.country && userCountry !== "HQ"){
                logoutHandler();
            }
            return (
                <Spin spinning={loading || saving || completing}>
                    <div className="applicationEditTop">
                        <Row justify='space-between'>
                            <Col>
                                <div className="applicationEditTitle">{application ? 'Update Application' : 'Application Not Found'}</div>
                            </Col>
                            <Col>
                                <Button 
                                    className='btn rejectColor'
                                    onClick={rejectBtnOnClick}>
                                    Reject
                                </Button>
                                <Button
                                    onClick={completeBtnOnClick}
                                    className='btn registerBtn'
                                    // icon={<UserAddOutlined />}
                                    disabled={!application || application.statusCh === ApplicationStatus.REJECTED || application.statusCh === ApplicationStatus.COMPLETE || application.statusCh === ApplicationStatus.RECIEVE} >
                                    Register User
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <Row justify='center' className="widgetRow" >
                        <Widget span={16}>
                            {renderErrorMessage()}
                            {renderWidgetContent()}
                        </Widget>
                    </Row>
                </Spin>
            );
        }
    }

    const fileOnClick = () => {
        setFileModalVisible(true);
    }

    const downloadOnClick = () => {
        if (application) {
            downloadApplicationFile();
        } else {
            showErrorMessage('No File To Downlaod');
        }
    }

    const fileModalOnCancel = () => {
        setFileModalVisible(false);
        setSelectedFile(null);
        setSelectedFileName(null);
    }
    const rejectBtnOnClick = (e) => {
        setRejectModalVisible(true);
    }

    const saveBtnOnClick = (e) => {

        // console.log(form.getFieldsValue());

        setSaving(true);
        updateApplication(application.id, form.getFieldsValue())
            .then(res => {
                if (res.status === 200 && res.data.code === 10000) {
                    showSuccessMessage('Success');
                    setErrorMessage(null);
                    getApplication(application.id);
                } else {
                    showErrorMessage('Update Fail');
                }
            })
            .catch(err => {
                console.debug(err);
                showErrorMessage('Update Fail');
                setErrorMessage({
                    title: "Update Fail",
                    message: err?.response?.data?.message
                });
            })
            .finally(() => {
                setSaving(false);
            })

    }

    const holdBtnOnClick = (e) => {
        setHoldModalVisible(true);
    }

    const holdModalOnClose = (e) => {
        setHoldModalVisible(false);
    }

    const holdConfirmBtnOnClick = () => {
        setSaving(true);
        holdApplication(application.id)
            .then(res => {
                if (res.status === 200 && res.data.code === 10000) {
                    showSuccessMessage('Success');
                    setErrorMessage(null);
                    setHoldModalVisible(false);
                    getApplication(application.id);
                } else {
                    showErrorMessage('Fail to hold application. Code: ' + res.data.code);
                }
            })
            .catch(err => {
                console.debug(err);
                showErrorMessage('Having issue to hold application');
            })
            .finally(() => {
                setSaving(false);
            })
    }

    const onRegisterModalOk = (e) => {
        setCompleting(true);
        completeApplication(application.id)
            .then(res => {
                if (res.status === 200 && res.data.code === 10000) {
                    showSuccessMessage('Success');
                    setErrorMessage(null);
                    setRegisterModalVisible(false);
                    getApplication(application.id);
                    // navigator(`/dashboard/application/${application?.id}/view`)
                } else {
                    showErrorMessage('Fail to complete application. Code: ' + res.data.code);
                }
            })
            .catch(err => {
                console.debug(err);
                showErrorMessage('Fail to register account');
                setErrorMessage({
                    title: 'Register Fail',
                    message: err?.response?.data?.message
                })
            })
            .finally(() => {
                setCompleting(false);
            })
    }

    const rejectConfirmOnClick = (e) => {
        setRejecting(true);
        rejectApplication(application.id)
            .then(res => {
                if (res.status === 200 && res.data.code === 10000) {
                    showSuccessMessage('Success');
                    setErrorMessage(null);
                    setRejectModalVisible(false);
                    getApplication(application.id);
                    // navigator(`/dashboard/application`)
                } else {
                    showErrorMessage('Fail to reject application. Code: ' + res.data.code);
                }
            })
            .catch(err => {
                console.debug(err);
                showErrorMessage('Fail to reject account');
                setErrorMessage({
                    title: 'Reject Fail',
                    message: err?.response?.data?.message
                })
            })
            .finally(() => {
                setCompleting(false);
            })
    }

    const completeBtnOnClick = () => {
        setRegisterModalVisible(true);
    }

    const onRegisterModalCancel = () => {
        setRegisterModalVisible(false);
    }
    const onRejectModalCancel = () => {
        setRejectModalVisible(false);
    }

    const uploadOnClick = () => {
        if (!selectedFile || !selectedFileName) {
            showWarningMessage('Please select file to upload');
            return;
        }

        const fileForm = new FormData();
        fileForm.append('files', selectedFile);

        setUploading(true);
        uploadApplicationFile(application.id, fileForm)
            .then(res => {
                if (res.status === 200 && res.data.code === 10000) {
                    showSuccessMessage('Upload Success');
                    fileModalOnCancel()
                    getApplication(application.id);
                } else {
                    console.debug('Fail to upload file', res.data);
                    showErrorMessage('Fail to upload file. Code:' + res.data.code);
                }
            })
            .catch(err => {
                console.debug('Having issue to upload file', err);
                showErrorMessage('Upload Failed');
                setErrorMessage({
                    title: 'Upload Failed',
                    message: err?.response?.data?.message
                })
            })
            .finally(() => {
                setUploading(false);
            })
    }

    const onSelectFile = (e) => {
        setSelectedFileName(e.target.value);
        setSelectedFile(e.target.files[0]);
    }

    const handleStatusStyle = (status) => {
        if(status === ApplicationStatus.RECIEVE){
            return('receivedColor');
        }else if(status === ApplicationStatus.COMPLETE){
            return('completedColor');
        }else if(status === ApplicationStatus.REJECTED){
            return('rejectedColor');
        }else if(status === ApplicationStatus.UNDER_REVIEW){
            return('underReviewColor');
        }else if(status === ApplicationStatus.ON_HOLD){
            return('onHoldColor');
        }
    }

    const renderWidgetContent = () => {
        if (!application) {
            return <Skeleton />
        } else {
            return (
                <div className="widgetWrapper">
                    <div className="widgetTop">
                        <Row justify='space-between'>
                            <Col>
                                Application Number: {application.applicationCodeCh}
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row justify='space-between'>
                            <Col>
                                <Row justify='space-between'>
                                    <Col>
                                        <Tag className={handleStatusStyle(application.statusCh)}>
                                            {application.statusCh}
                                        </Tag>
                                    </Col>
                                    <Col>
                                        <Tooltip title={`Download File ${application.fileNameCh}`}>
                                            <Button
                                                disabled={downloading}
                                                onClick={fileOnClick}
                                                className='btn'
                                                type='primary'
                                                icon={<PaperClipOutlined />}>
                                                File
                                            </Button>
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Button
                                    className='btn holdBtn'
                                    onClick={holdBtnOnClick}
                                    icon={<PushpinOutlined />}
                                    disabled={!application || (application.statusCh === ApplicationStatus.REJECTED || application.statusCh === ApplicationStatus.ON_HOLD || application.statusCh === ApplicationStatus.COMPLETE)} >
                                    Hold
                                </Button>
                                <Button
                                    className='btn'
                                    type='primary'
                                    onClick={saveBtnOnClick}
                                    icon={<SaveOutlined />}
                                    disabled={!application || (application.statusCh === ApplicationStatus.REJECTED || application.statusCh === ApplicationStatus.COMPLETE)} >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <div className="widgetContent" >
                        <Form form={form} validateMessages={formValidateMessages} >
                            <Row justify='center'>
                                <Col>
                                    <div className='appliactionInfo'>
                                        <div className='formTitle'>
                                            Basic Info
                                        </div>
                                        <Row>
                                            <Col span={5}>
                                                <Form.Item name={['isNewAccount']} className='inputLabel' label={<Tooltip title="Is this require new account?">New Account</Tooltip>} labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Checkbox disabled defaultChecked={application.isNewAccount} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={5}>
                                                <Form.Item name={['isFulfillment']} className='inputLabel' label={<Tooltip title='Does shipper need Yamato Fulfilment?'>Fulfillment</Tooltip>} labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Checkbox defaultChecked={application.isFulfillment} onChange={(e) => form.setFieldsValue({ isFulfillment: e.target.checked })} />
                                                </Form.Item>
                                            </Col>
                                            <Col offset={1} span={11}>
                                                <Form.Item required name={['channelCh']} className='inputLabel' label='E-Commerce Platform' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Select>
                                                        {renderChannelOptions()}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['destinationCountryCh']} className='inputLabel' label='Available Destination Countries' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <DestinationCountrySelect />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['businessTypeCh']} className='inputLabel' label='Biz Type' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Select>
                                                        <Select value={1}>B2C</Select>
                                                        <Select value={2}>B2B</Select>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item
                                                    name={['username']}
                                                    rules={[
                                                        { required: true },
                                                        { pattern: pattern.username, message: 'username is not valid' }
                                                    ]}
                                                    required
                                                    className='inputLabel'
                                                    label='Username'
                                                    labelCol={{ span: 24 }}
                                                    wrapperCol={{ span: 22 }} >
                                                    <Input autoComplete='off' className='inputValue' type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['companyCh']} className='inputLabel' label='Company Name' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input className='inputValue' type='text' maxLength={50} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['firstName']} className='inputLabel' label='First Name' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input className='inputValue' type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['lastName']} className='inputLabel' label='Last Name' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input className='inputValue' type='text' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row justify='center'>
                                <Col>
                                    <div className='applicationInfo'>
                                        <div className='formTitle'>Shipper Info</div>
                                        <Row>
                                            <Col span={11}>
                                                <Form.Item required name={['shipperCh']} className='inputLabel' label='Shipper Code' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input autoComplete='off' className='inputValue' type='text' maxLength={15} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['serviceTypeCh']} className='inputLabel' label='Service Type' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }}>
                                                    <Select className='inputValue'>
                                                        {renderServiceOptions()}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['shipperNameCh']} className='inputLabel' label='Shipper Name' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input autoComplete='off' type='text' maxLength={60} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['shipperShortNameCh']} className='inputLabel' label='Short Name' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input autoComplete='off' type='text' maxLength={8} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['branchId']} className='inputLabel' label='Branch' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Select
                                                        // custom filter with show name
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        showSearch
                                                        className='inputValue'>
                                                        {renderBranchOptions()}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['portOfLoadingCh']} className='inputLabel' label={<Tooltip title='Port Of Loading'>POL</Tooltip>} labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Select showSearch className='inputValue'>
                                                        {renderPolOptions()}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['weightUnitCh']} className='inputLabel' label='Weight Unit' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Select className='inputValue'>
                                                        {renderWeightOptions()}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['currencyCh']} className='inputLabel' label='Currency' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Select className='inputValue'>
                                                        {renderCurrencyOptions()}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['lengthUnitCh']} className='inputLabel' label='Length Unit' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Select className='inputValue'>
                                                        {renderLengthOptions()}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['paymentCh']} className='inputLabel' label='Default Payment Term' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Select className='inputValue'>
                                                        {renderPaytermOptions()}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['additionalPaymentsCh']} className='inputLabel' label='Payment Term(s)' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <PaymentTermsSelect />
                                                </Form.Item>
                                            </Col>
                                            <Col span={5}>
                                                <Form.Item name={['volumeWeight']} className='inputLabel' label={<Tooltip title='Does the Shipper wants to see the volume weight?'>Volume Weight?</Tooltip>} labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Checkbox disabled defaultChecked={application.volumeWeight} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col >
                                    <div className='applicationInfo'>
                                        <div className='formTitle'>Address</div>
                                        <Row>
                                            <Col span={20}>
                                                <Form.Item required name={['attention']} className='inputLabel' label='Attention' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input autoComplete='off' maxLength={50} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['address']} className='inputLabel' label='Address' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input autoComplete='off' maxLength={35} required />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['address2']} className='inputLabel' label='Address 2' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input autoComplete='off' maxLength={35} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['city']} className='inputLabel' label='City' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input autoComplete='off' maxLength={35} required />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['email']} className='inputLabel' label='Email' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input autoComplete='off' type='email' required />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['state']} className='inputLabel' label='State' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input autoComplete='off' maxLength={35} required />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['phone']} className='inputLabel' label='Telephone' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input autoComplete='off' maxLength={15} required />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['country']} className='inputLabel' label='Country' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <CountrySelect addition={[{value: 'JP', name: 'Japan'}]} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['mobile']} className='inputLabel' label='Mobile' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input autoComplete='off' maxLength={15} required />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['zipcode']} className='inputLabel' label='Zipcode' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input autoComplete='off' maxLength={5} required />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item required name={['homePage']} className='inputLabel' label='Shop URL' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input autoComplete='off' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name={['memoTxt']} className='inputLabel' label='Memo' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input.TextArea autoComplete='off' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            );
        }
    }

    const renderModals = () => {
        if (application) {
            return (
                <>
                    <Modal
                        title="Register User?"
                        visible={registerModalVisible}
                        onCancel={onRegisterModalCancel}
                        footer={[
                            <Button className='btn' type='primary' disabled={completing} onClick={onRegisterModalOk}>Yes</Button>
                        ]}>
                        <p>Please make sure save before you complete the registration.</p>
                        <p>Do you want to register this user to Y-Ship Cloud?</p>
                    </Modal>
                    <Modal
                        title="Reject Application?"
                        visible={rejectModalVisible}
                        onCancel={onRejectModalCancel}
                        footer={[
                            <Button className='btn' type='primary' disabled={rejecting} onClick={rejectConfirmOnClick}>Yes</Button>
                        ]}>
                        <p>Do you want to reject this application?</p>
                    </Modal>
                    <Modal
                        title="Hold Application?"
                        visible={holdModalVisible}
                        onCancel={holdModalOnClose}
                        footer={[
                            <Button className='btn' type='primary' disabled={saving} onClick={holdConfirmBtnOnClick}>Yes</Button>
                        ]}>
                        <p>Please make sure save changes before you hold the application.</p>
                        <p>Do you want to hold this application?</p>
                    </Modal>
                    <Modal title='Application File' visible={fileModalVisible} onCancel={fileModalOnCancel} footer={null}>
                        <Row>
                            {application.fileNameCh ? application.fileNameCh : 'No File Upload'} <Button disabled={downloading} onClick={downloadOnClick} className='filtBtn' icon={<CloudDownloadOutlined />}>Download</Button>
                        </Row>
                        <Divider />
                        <Row>
                            <Form.Item label='Upload New File'>
                                <Input type='file' onChange={onSelectFile} value={selectedFileName} disabled={uploading} placeholder='Upload New File' />
                            </Form.Item>
                            <Button type='primary' className='filtBtn' onClick={uploadOnClick} disabled={uploading} >Upload</Button>
                        </Row>
                    </Modal>
                </>
            )
        } else {
            return <React.Fragment />
        }
    }

    return (
        <div className='applicationEditPageWrapper'>
            {renderPage()}
            {renderModals()}
        </div>
    )
}

export default ApplicationEdit;