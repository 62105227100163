import axios from "axios";
import { getApiHeaderNotTime } from "../utils/getApiHeaders";

export function getOptions() {
    const options = {
        method: 'GET',
        url: process.env.REACT_APP_URL + `/common/editoptions`,
        headers: getApiHeaderNotTime(),
    }

    return axios(options)
}