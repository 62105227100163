import { Button, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import React from 'react';
import PropTypes from 'prop-types';

const ReloadBtn = ({ type, htmlType, className, onClick, loading, toolTipTitle }) => {
    return (
        <Tooltip title={toolTipTitle}>
            <Button htmlType={htmlType} type={type} icon={<ReloadOutlined spin={loading} />} className={`btn ${className}`} onClick={onClick} />
        </Tooltip>
    )
}

ReloadBtn.propTypes = {
    type: PropTypes.string,
    htmlType: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    toolTipTitle: PropTypes.string
}

ReloadBtn.defaultProps  = {
    type: 'primary',
    htmlType: 'button',
    toolTipTitle: 'Reload'
}


export default ReloadBtn;