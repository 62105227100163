import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Checkbox, Col, Collapse, Divider, Form, Input, Layout, Result, Row, Select, Space, Spin, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons'
import step1Img from '../../resource/img/submit.png';
import step2Img from '../../resource/img/review.png';
import step3Img from '../../resource/img/check.png';
import './UserRegister.css';
import { Link } from 'react-router-dom';
import { showErrorMessage, showSuccessMessage } from '../../components/message';
import { getFileExt } from '../../utils/helper';
import { submitApplication } from '../../apis/application';
import { formValidateMessages, pattern } from '../../utils/patterns';
import CountrySelect from '../../components/countrySelect/CountrySelect';
import TooltipLabel from '../../components/tooltipLabel/TooltipLabel';

const formInit = {
    isNewAccount: true,
    isFulfillment: false,
    username: '',
    password: '',
    passwordConfirm: '',
    address: '',
    city: '',
    state: '',
    zipcode: ''
}

const UserRegister = () => {

    let [form] = Form.useForm();
    const [isNew, setIsNew] = useState(true);
    const [fulfillmentDisabled, setFulfillmentDisabled] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [applicationNumber, setApplicationNumber] = useState();
    const [errorMessage, setErrorMessage] = useState('');

    const [isAgree, setIsAgree] = useState(false);
    const [volumeWeight, setVolumeWeight] = useState(false);

    const onFormSubmit = (f) => {
        console.log('on submit', f);

        if (!validateApplicationForm(f)) {
            return;
        }

        let submitForm = new FormData();
        for (let key in f) {
            if (f[key]) {
                submitForm.append(key, f[key]);
            }
        }

        if (selectedFile) {
            submitForm.append('productFile', selectedFile);
        }

        // console.log('application submit', submitForm);
        // for (var value of submitForm.values()) {
        //     console.log(value);
        // }


        setSubmitting(true);
        submitApplication(submitForm)
            .then(res => {
                if (res.status === 200 && res.data.code === 10000) {
                    showSuccessMessage('Application Submit Successfully');
                    setApplicationNumber(res.data.payload);
                    form.resetFields();
                    setErrorMessage(null);
                } else {
                    console.log(res.data);
                    showErrorMessage('Fail to submit the application. Code: ' + res.data.code);
                }
            })
            .catch(err => {
                console.error('Here is error' + err);

                setErrorMessage(err.response.data.message);
            })
            .finally(() => {
                setSubmitting(false);
            })

    }

    const validateApplicationForm = (form) => {
        if (!form.username || !form.password) {
            setErrorMessage('Please provide username and password!')
            return false;
        }
        if (form.password !== form.passwordConfirm) {
            setErrorMessage('Password and Password Confirm must match!');
            return false;
        }

        if (!form.companyName || !form.firstName || !form.lastName) {
            setErrorMessage('Please provide shipper information!');
            return false;
        }

        if (!form.address || !form.city || !form.state || !form.zipcode || !form.country || !form.email || !form.phone) {
            setErrorMessage('Please provide seller address information!');
            return false;
        }

        if (!isAgree) {
            setErrorMessage('Please agree the Yamato Term & Policy!');
            return false;
        }

        return true;
    }

    const onSelectFile = (e) => {
        console.log('file selected', e);

        let ext = getFileExt(e.target.value).toLowerCase();

        if (ext !== 'csv' && ext !== 'tsv' && ext !== 'xls' && ext !== 'xlsx') {
            setErrorMessage('Product file only accept flat file (csv/tsv) and Excel (xls/xlsx) format');
            setSelectedFile(null);
            setSelectedFileName(null);
            form.setFieldsValue({
                productFile: null
            });
        } else {
            setSelectedFileName(e.target.value);
            setSelectedFile(e.target.files[0]);
            form.setFieldsValue({
                productFile: e.target.files[0]
            });
        }
    }

    const isNewOnChange = (e) => {
        setIsNew(!e.target.checked);
        form.setFieldsValue({
            isNewAccount: !e.target.checked
        })
    }

    const isFulfillmentOnChange = (e) => {
        form.setFieldsValue({
            isFulfillment: e.target.checked
        })
    }

    const volumeWeightChange = (e) => {
        form.setFieldsValue({
            volumeWeight: e.target.checked
        })
    }

    const renderErrorMessage = () => {
        if (errorMessage) {
            return (
                <Alert
                    message="Error"
                    description={errorMessage}
                    type='error'
                    showIcon
                />
            );
        }
    }

    const agreeOnChange = (e) => {
        setIsAgree(e.target.checked);
    }

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
            password: '${label} is not match the requirement!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };

    const renderContent = () => {
        if (applicationNumber) {
            // render success message
            return (
                <Result
                    status="success"
                    title="Your application has been recieved."
                    subTitle={`Application number: ${applicationNumber} we will review your application as soon as possible, please wait further email notification.`}
                />
            );
        } else {
            // render application form
            return (
                <Row className='registerFormWrapper' justify='center'>
                    <div className='registerForm'>
                        <Collapse className='steps' defaultActiveKey={[1]} >
                            <Collapse.Panel header='How To Register' key={1} >
                                <div className="site-card-wrapper">
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <Card className='stepCard'>
                                                <div className='stepImgWrapper'>
                                                    <img className='stepImg' src={step1Img} />
                                                </div>
                                                <div className='stepTitle'>
                                                    Step 1: Submit Application

                                                </div>
                                                <div className='stepDescription'>
                                                    <p>Fill out the registration form below and upload your product data file containing information such as SKU, Marketplace ID (e.g., ASIN), Title, Description, etc. The product data file should be in text (CSV/TSV), or Excel (XLS, XLSX) format.</p>
                                                </div>
                                            </Card>
                                        </Col>
                                        <Col span={8}>
                                            <Card className='stepCard'>
                                                <div className='stepImgWrapper'>
                                                    <img className='stepImg' src={step2Img} />
                                                </div>
                                                <div className='stepTitle'>
                                                    Step 2: Yamato Review
                                                </div>
                                                <div className='stepDescription'>
                                                    <p>
                                                        Yamato will perform a thorough compliance check based on the provided information. Depending on the product type and volume, this may take a few days. Through this check, if we find any potential problems for customs clearance for import into Japan for one or more of your products, you will be contacted by a customer service representative to discuss how to resolve those issues.
                                                    </p>
                                                </div>
                                            </Card>
                                        </Col>
                                        <Col span={8}>
                                            <Card className='stepCard'>
                                                <div className='stepImgWrapper'>
                                                    <img className='stepImg' src={step3Img} />
                                                </div>
                                                <div className='stepTitle'>
                                                    Step 3: Registration Complete
                                                </div>
                                                <div className='stepDescription'>
                                                    <p>
                                                        Once the review is complete, an email will be sent to you with the login information for the new account. Please follow the directions in the email to get started with Y-Ship Cloud.
                                                    </p>
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </Collapse.Panel>
                        </Collapse>
                        <Form
                            validateMessages={formValidateMessages}
                            autoComplete='off'
                            onFinish={onFormSubmit}
                            form={form}
                            labelCol={{ span: 24 }}
                            // wrapperCol={{ span: 17 }}
                            className='form'
                            initialValues={formInit}
                        >
                            <div className='formSection'>
                                <div className='formTitle'>Account</div>
                                <Row className='formRow' justify='start'>
                                    <Col span={11}>
                                        <Form.Item hidden name={['isNewAccount']}></Form.Item>
                                        <Form.Item
                                            label={<TooltipLabel label={'Username'} tooltip={<p>Please use an email address</p>} />}
                                            required={true}
                                            rules={[
                                                { required: true },
                                                { pattern: pattern.email, message: 'username is not valid' }
                                            ]}
                                            name={['username']}>
                                            <Input autoComplete='off' maxLength={100} placeholder='Username' />
                                        </Form.Item>
                                        <Form.Item
                                            label={<TooltipLabel label={'Password'} tooltip={<p>Your password must be at least 8 characters long and contain: <ul><li>1 lower case character </li><li>1 upper case character</li><li> 1 special character: !@#$%^&*</li></ul></p>} />}
                                            required={true}
                                            rules={[
                                                { required: true },
                                                {
                                                    pattern: pattern.password,
                                                    message: 'your password must at least 8 characters and contain: 1 lower case charater, 1 upper case character, 1 special character: !@#$%^&*'
                                                }]}
                                            name={['password']} >
                                            <Input autoComplete='off' maxLength={32} type='password' placeholder='Password' />
                                        </Form.Item>
                                        <Form.Item label={<TooltipLabel label={'Password Confirm'} />}
                                            // labelCol={{ span: 8 }}
                                            required={true}
                                            rules={[{ required: true }]}
                                            name={['passwordConfirm']}>
                                            <Input autoComplete='off' type='password' placeholder='Password Confirm' />
                                        </Form.Item>
                                    </Col>
                                    <Col offset={1} span={11}>
                                        <Form.Item label='E-Commerce Platform' name={['channel']} rules={[{ required: true }]}>
                                            <Select defaultValue={null}>
                                                <Select.Option value={null} >Select</Select.Option>
                                                <Select.Option value={2}>Amazon Japan</Select.Option>
                                                <Select.Option value={3}>Rakuten Japan</Select.Option>
                                                <Select.Option value={4}>Buyma / Other</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label='Shop URL' name={['homePage']}>
                                            <Input type={'text'} maxLength={60} />
                                        </Form.Item>
                                        <Form.Item label='Country' name={['country']} rules={[{ required: true }, {pattern: pattern.alphnum, message: 'last name can only contain alphabetic letter, and special characters'}]}>
                                            <CountrySelect />
                                        </Form.Item>
                                        <Form.Item label={<TooltipLabel label={'Yamato Fulfillment'} tooltip={<p>Let Yamato fulfill your orders.<br /> This requires an additional operation fee. Currently Korea is not supported with this service.</p>} />} name={['isFulfillment']} >
                                            <Checkbox defaultChecked={form.getFieldValue('isFulfillment')} onChange={isFulfillmentOnChange} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        
                            <div className='formSection'>
                                <div className='formTitle'>Shipper Information</div>
                                <Row className='formRow'>
                                    <Col span={11}>
                                        <Form.Item label='Company Name' required={true} name={['companyName']} rules={[{ required: true }, {pattern: pattern.alphnum, message: 'company name can only contain alphabetic letter, number, and special characters'}]} >
                                            <Input maxLength={30} type='' placeholder='Company Inc.' />
                                        </Form.Item>
                                        <Form.Item label='First Name' required={true} name={['firstName']} rules={[{ required: true }, {pattern: pattern.alphnum, message: 'first name can only contain alphabetic letter, number, and special characters'}]}>
                                            <Input maxLength={50} type='' placeholder='John' />
                                        </Form.Item>
                                        <Form.Item label='Last Name' required={true} name={['lastName']} rules={[{ required: true }, {pattern: pattern.alphnum, message: 'last name can only contain alphabetic letter, number, and special characters'}]}>
                                            <Input maxLength={50} type='' placeholder='Doe' />
                                        </Form.Item>
                                        <Form.Item label='Email Address' required={true} name={['email']} rules={[{ required: true },{ pattern: pattern.email, message: 'email address is not valid' }]}>
                                            <Input type='email' maxLength={50} placeholder='someone@examel.com' />
                                        </Form.Item>
                                        <Form.Item label='Telephone' required={true} name={['phone']} rules={[{ required: true }, {pattern: pattern.phonenum}]} >
                                            <Input type='tel' maxLength={15} placeholder='###-###-####' />
                                        </Form.Item>
                                        <Form.Item label='Mobile' name={['mobile']} rules={[{pattern: pattern.phonenum}]} >
                                            <Input type='tel' maxLength={15} placeholder='###-###-####' />
                                        </Form.Item>
                                        <Form.Item label={<TooltipLabel label={'Show Volume Weight?'} tooltip={<p>An estimated Package Volume Weight will display when entering package information into Y-Ship Cloud</p>} />} name={['volumeWeight']}  >
                                            <Checkbox defaultChecked={form.getFieldValue('volumeWeight')} onChange={volumeWeightChange} />
                                        </Form.Item>
                                    </Col>
                                    <Col offset={1} span={11}>
                                        <Form.Item label={<TooltipLabel label={'Address'} tooltip={<p>Please do not use a P.O Box.</p>} />} required={true} name={['address']} rules={[{ required: true }, {pattern: pattern.alphnum, message: 'address can only contain number, alphabetic letter, and special characters'}]}>
                                            <Input defaultValue={''} maxLength={35} type='text' placeholder='Example St.' />
                                        </Form.Item>
                                        <Form.Item label='Address 2' name={['address2']} rules={[{pattern: pattern.alphnum, message: 'address can only contain number, alphabetic letter, and special characters'}]} >
                                            <Input maxLength={35} type='text' placeholder='APT #'/>
                                        </Form.Item>
                                        <Form.Item label='City' required={true} name={['city']} rules={[{ required: true }, {pattern: pattern.alphnum, message: 'city can only contain alphabetic letter, and special characters'}]}>
                                            <Input maxLength={35} type='text' placeholder='City Name' />
                                        </Form.Item>
                                        <Form.Item label='State' required={true} name={['state']} rules={[{ required: true }, {pattern: pattern.alphnum, message: 'state can only contain number, alphabetic letter, and special characters'}]}>
                                            <Input maxLength={35} type='text' placeholder='State' />
                                        </Form.Item>
                                        <Form.Item label='Zip/Postal Code' required={true} name={['zipcode']} rules={[{ required: true }, {pattern: pattern.alphnum, message: 'zip code can only contain number, alphabetic letter, and special characters'}]}>
                                            <Input defaultValue={''} type='' maxLength={10} placeholder='Zip Code' />
                                        </Form.Item>
                                        <Form.Item label='Memo' name={['memo']} rules={[{pattern: pattern.alphnum, message: 'memo can only contain alphabetic letter, numbers, and special characters'}]}>
                                            <Input.TextArea cols={80} rows={4} style={{ width: 'auto' }} placeholder='Memo' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>

                            <div className='formSection'>
                                <div className='formTitle'>Product Data File <Tooltip title="This is for Amazon sellers only"><QuestionCircleOutlined /></Tooltip></div>
                                <Row className='formRow'>
                                    <Col span={12}>
                                        <Form.Item label='Upload File' name={['productFile']}>
                                            <Input type='file' onChange={onSelectFile} value={selectedFileName} placeholder='No Product File Selected' />
                                            <span>Only accept CVS (csv/tsv) and Excel (xls/xlsx) type.<br />Max file size allowed - 10MB</span>
                                        </Form.Item>
                                        <Form.Item>
                                            <Checkbox checked={isAgree} onChange={agreeOnChange} /> I agree to Y-Ship Cloud's <a target="_blank" href="https://ys.yamatoamerica.com/terms-and-conditions">{'Terms & Conditions'}</a>, <a target="_blank" href='https://www.yamatoamerica.com/privacy-policy/'>Privacy Policy</a>, and <a target="_blank" href='https://www.yamatoamerica.com/payment-terms/'>Payment Terms & Conditions</a>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {renderErrorMessage()}
                                <Row justify='end'>
                                    <Form.Item required={true}>
                                        <Button htmlType='submit' className='btn' >Submit</Button>
                                    </Form.Item>
                                </Row>
                            </div>
                        </Form>
                    </div>
                </Row>
            )
        }
    }

    return (
        <div className='userRegisterPageWrapper'>
            <Spin spinning={submitting}>
                <Row justify='center'>
                    <h1 className='pageTitle'>Account Registration</h1>
                </Row>
                {renderContent()}
            </Spin>
        </div>
    );
}

export default UserRegister;