import { Select } from 'antd';
import propTypes from 'prop-types';
import React from 'react';
import { PaymentTerms } from '../../constants';

const PaymentTermsSelect = ({value, addition, disabled, onChange}) => {

    const options = [];

    for (var term in PaymentTerms) {
        options.push({
            id: term,
            paymentTerm: PaymentTerms[term]
        })
    }
   let payments = [];
   value = String(value);
   if(value.length > 0)
   {
        payments = value.split(",");
        payments.sort();
   }
    return (
        <Select maxLength={20} value={payments} mode="multiple" disabled={disabled} onChange={onChange}>
            {options.map((paymentTermToDisplay) => {
                return <Select.Option value={paymentTermToDisplay.id} >{paymentTermToDisplay.paymentTerm}</Select.Option>
            })}
        </Select>
    )
}

PaymentTermsSelect.defaultProps = {
    value: null,
    addition: [],
    disabled: false,
}

export default PaymentTermsSelect;