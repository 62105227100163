import { Col, Row, Form, Input, Button, Checkbox, Spin } from "antd";
import React, { useContext, useState, useEffect } from "react";
import Widget from "../../components/widget/Widget";
import { tryLogin } from '../../apis/auth';
import { AuthContext } from "../../contexts/AuthContext";
import { showErrorMessage } from "../../components/message";
import './Login.css';
import { useNavigate } from "react-router-dom";
import { pattern } from "../../utils/patterns";
import { formValidateMessages } from '../../utils/patterns'

const Login = () => {
  const [authState, authReducer] = useContext(AuthContext);
  let navigator = useNavigate();

  const { login } = authState;

  const [inLogin, setInLogin] = useState(false);
  const [credential, setCredential] = useState({
    username: "",
    password: "",
  });

  const startLogin = () => {
    setInLogin(true);
  }

  const endLogin = () => {
    setInLogin(false);
  }

  useEffect(() => {
    if (login) {
      navigator('/dashboard');
    }
  }, [login]);

  const loginOnClick = () => {
    if (credential.username && credential.password) {
      startLogin();
      tryLogin(credential).then(res => {
        if (res.status === 200 && res.data.code === 10000) {
          var accessToken = res.data.payload.token.value;
          authReducer({ type: 'LOGIN', payload: accessToken });
        } else {
          showErrorMessage('Login Fail');
        }
      }).catch(err => {
        console.error(err);
        showErrorMessage(err?.response?.data?.message);
      }).finally(() => {
        endLogin();
      })
    }
  };

  const renderLoginButton = () => {
    if (inLogin) {
      return (
        <Button
          className="signInBtn"
          type="primary"
          htmlType="submit"
          style={{ borderRadius: "5px" }}
          onClick={loginOnClick}
          disabled={inLogin}
        >
          Sign In<Spin />
        </Button>
      );
    } else {
      return (
        <>
          <Button
            className="signInBtn"
            type="primary"
            htmlType="submit"
            style={{ borderRadius: "5px" }}
            onClick={loginOnClick}
          >
            Sign In
          </Button>
        </>
      );
    }
  }

  return (
    <Row align="middle" justify="center" style={{ height: "80vh" }}>
      <Widget span={8}>
        <Row className="loginTop" justify="center">
          <Col
            className="loginTitle"
          >
            Y-SHIP
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <p
              className="loginDescription"
              style={{ color: "gray", fontWeight: 300 }}
            >
              Please enter username and password
            </p>
          </Col>
        </Row>
        <Row
          className="loginForm"
          style={{ marginTop: "10px" }}
          justify="center"
        >
          <Col>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              //   onFinish={onFinish}
              //   onFinishFailed={onFinishFailed}
              autoComplete="off"
              validateMessages={formValidateMessages}
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  { required:true },
                ]}
              >
                <Input
                  value={credential.username}
                  disabled={inLogin}
                  onChange={(e) =>
                    setCredential({ ...credential, username: e.target.value })
                  }
                />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true},
                ]}
              >
                <Input.Password
                  value={credential.password}
                  disabled={inLogin}
                  onChange={(e) =>
                    setCredential({ ...credential, password: e.target.value })
                  }
                />
              </Form.Item>

              <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{ offset: 8, span: 16 }}
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
                {renderLoginButton()}
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Widget>
    </Row>
  );
};

export default Login;
