import axios from "axios";
import { getApiHeaderNotTime } from "../utils/getApiHeaders";

export function getUsersByCountry(searchCondition) {
    const options = {
        method: 'GET',
        url: process.env.REACT_APP_URL + '/user',
        headers: getApiHeaderNotTime(),
        params: {
            text: searchCondition.text,            
            country: searchCondition.country,
            pageNumber: searchCondition.pageNumber,
            pageSize: searchCondition.pageSize
        }
    }

    return axios(options)
}
export function getUsers(searchCondition) {
    const options = {
        method: 'GET',
        url: process.env.REACT_APP_URL + '/user',
        headers: getApiHeaderNotTime(),
        params: {
            text: searchCondition.text,
            pageNumber: searchCondition.pageNumber,
            pageSize: searchCondition.pageSize
        }
    }
    return axios(options)
}
export function getUserById(id) {
    const options = {
        method: 'GET',
        url: process.env.REACT_APP_URL + `/user/${id}`,
        headers: getApiHeaderNotTime(),
    }

    return axios(options)
}

export function updateUserInfo(userId, form){
    const options = {
        method: 'PUT',
        url: process.env.REACT_APP_URL + `/user/${userId}/updateinfo`,
        headers: getApiHeaderNotTime(),
        data: {
            username: form.username,
            firstName: form.firstName,
            lastName: form.lastName,
            email: form.email,
            role: form.role
        }
    }

    return axios(options)
}