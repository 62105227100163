import React, { createContext, useReducer } from 'react';
import { ApplicationStatus } from '../constants';
import applicationsReducer from '../reducers/applicationsReducer';


export const ApplicationsContext = createContext();

const initAppState = {
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
    totalItems: 0,
    status: ApplicationStatus.UNDER_REVIEW,
    items: [],
    text:'',
    country:null,
};

export const ApplicationsContextProvider = props => {
    const [state, dispatch] = useReducer(applicationsReducer, initAppState);

    return (
        <ApplicationsContext.Provider value={[state, dispatch]}>
            {props.children}
        </ApplicationsContext.Provider>
    );
};