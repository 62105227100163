import { Select } from 'antd';
import propTypes from 'prop-types';
import React from 'react';

const CountrySelect = ({value, addition, disabled, onChange}) => {

    const renderAdditionOptions = () => {
        if(addition){
            return addition.map(a => {
                return <Select.Option value={a.value}>{a.name}</Select.Option>
            });
        }
    }

    return (
        <Select maxLength={2} value={value} disabled={disabled} onChange={onChange} >
            <Select.Option value={null}>Select Country</Select.Option>
            <Select.Option value={'US'}>United State</Select.Option>
            <Select.Option value={'CA'}>Canada</Select.Option>
            <Select.Option value={'KR'}>South Korea</Select.Option>
            {renderAdditionOptions()}
        </Select>
    )
}


// CountrySelect.propTypes = {
//     value: propTypes.any(propTypes.string, propTypes.object),
//     countryOnChange: propTypes.func,
// }

CountrySelect.defaultProps = {
    value: null,
    addition: [],
    disabled: false,
}

export default CountrySelect;