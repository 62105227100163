import Cookies from 'js-cookie';
import { parseUrlFromString } from './helper'
import Storage from './Storage';
import { Modal } from 'antd';
import { PerfixCookieNotDelete } from '../constants';
// import history from './history';

/**
 * set auth token to cookie
 */
export const setTokenCookie = (token) => {
    var pareUrl = parseUrlFromString(process.env.REACT_APP_URL);
    // set day expires
    var objOptionsCookie = {
        expires: process.env.REACT_APP_TIME_OUT_CLIENT / 86400000,
    };
    if (pareUrl && pareUrl.domain) {
        objOptionsCookie.domain = '.' + pareUrl.domain;
    }
    if (!token) {
        token = localStorage.getItem('access_token');
        //token = Math.random().toString(36).substring(7);
    }
    Cookies.set('access_token', token, objOptionsCookie);
};

/**
 * Logout progress = call api logout + remove all data
 */
export const logoutProcess = () => {
    // logout(); // ajax call logout
    Storage.clearAll();
    Modal.destroyAll();
    removeAllCookie();
    // history.push('/login');
};

/**
 * remove all cookie
 */
export const removeAllCookie = () => {
    var ignores = [
        "file_format",
        "file_type",
        "include_header",
    ];
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        name = name.trim();
        var regex = new RegExp(PerfixCookieNotDelete)
        if (ignores.indexOf(name) > -1 || regex.test(name)) {
            continue;
        }
        Cookies.remove(name);
    }
};