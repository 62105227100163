import { Select } from 'antd';
import propTypes from 'prop-types';
import React from 'react';
import { DefaultPaymentTerms } from '../../constants';

const DefaultPaymentTermSelect = ({value, addition, disabled, onChange}) => {

    const options = [];

    for(var i in DefaultPaymentTerms) {
      var obj = {};
      obj['id'] = i;
      obj['paymentTerm'] = DefaultPaymentTerms[i];
      options.push(obj);
    }

    return (
        <Select maxLength={2} value={value} disabled={disabled} onChange={onChange}>
            <Select.Option value={null}>Select Default Payment Term</Select.Option>
            {options.map((DefaultPaymentTerms) => {
                return <Select.Option value={DefaultPaymentTerms.id} >{DefaultPaymentTerms.paymentTerm}</Select.Option>
            })}
        </Select>
    )
}

DefaultPaymentTermSelect.defaultProps = {
    value: null,
    addition: [],
    disabled: false,
}

export default DefaultPaymentTermSelect;