import React, { useState, useEffect, useContext } from 'react';
import { Col, Row, Spin, Button, Collapse, Table, Form, Input, Select } from 'antd';
import { UserAddOutlined, EditOutlined } from '@ant-design/icons';
import Widget from '../../components/widget/Widget';
import { searchShipperss } from '../../apis/shipper';
import { showErrorMessage } from '../../components/message';
import { renderSelectOptions } from '../../utils/helper';
import { AppContext } from '../../contexts/AppContext';
import './Shippers.css';
import { useNavigate } from 'react-router-dom';
import ReloadBtn from '../../components/reloadBtn/ReloadBtn';
import CustomTable from '../../components/CustomTable/CustomTable';
import { getUserProfile as getUserProfileApi } from '../../apis/auth';
import { AuthContext } from '../../contexts/AuthContext';
import { AuthAction } from "../../reducers/authReducer";


const Shippers = () => {

    const [filter, setFilter] = useState(
        {
            name: '',
            shipperCode: '',
            branch: '',
            serviceType: '',
            country: ''
        }
    )
    const [pagination, setPagination] = useState({
        pageNumber: 1,
        pageSize: 10,
        total: 0,
    })

    const [shippers, setShippers] = useState([])
    const [loading, setLoading] = useState(false);
    const [userCountry, setUserCountry] = useState('');
    const [loadProfile, setLoadProfile] = useState(false);

    const [appState, appDispatch] = useContext(AppContext);
    const [authState, authDispatch] = useContext(AuthContext);
    let navigate = useNavigate();

    const { options } = appState;

    const tableColumns = [
        {
            title: 'Name',
            dataIndex: 'shipperNameCh',
            key: 'shipperNameCh',
        },
        {
            title: 'Shipper Code',
            dataIndex: 'shipperCh',
            key: 'shipperCh',
        },
        {
            title: 'Service Type',
            dataIndex: 'serviceType',
            key: 'serviceType',
        },
        {
            title: 'Branch',
            dataIndex: 'branchShortNameCh',
            key: 'branchShortNameCh',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            render: (t, r) => {
                return `${r.address1Ch} ${r.address2Ch} (${r.countryCh})`;
            }
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: 10,
            render: (t, r) => {
                return <Button key={r.id} className='tableBtn' onClick={(e) => navigate(`/dashboard/shipper/${r.id}`)} icon={<EditOutlined />} />
            }
        }
    ]

    useEffect(() => {
        getUserProfile();
    }, [])
    useEffect(() => {
        if(loadProfile){
            fetchShippers(filter);
        }
    }, [loadProfile])

    const getUserProfile = () => {
        
        setLoading(true);
        getUserProfileApi()
          .then(res => {
            if (res.status === 200 && res.data.code === 10000) {
                authDispatch({ type: AuthAction.SET_USER_PROFILE, payload: res.data.payload });
                setUserCountry(res.data.payload.country.trim());
                if(res.data.payload.country.trim() != "HQ"){
                    let newFilter = {...filter};
                    newFilter['country'] = res.data.payload.country.trim();
                    setFilter({...newFilter});
                }
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            setLoadProfile(true);
          })
    }
    const fetchShippers = (search) => {
        setLoading(true);
        searchShipperss(search)
            .then(res => {
                if (res.status === 200 && res.data.code === 10000) {

                    const data = res.data.payload;

                    setShippers(data.items);

                    setPagination({
                        ...pagination,
                        pageNumber: data.currentPage,
                        pageSize: data.pageSize,
                        total: data.totalCount
                    })

                } else {
                    showErrorMessage('Fail to get shippers. Code: ' + res.data.code);
                }
            })
            .catch(err => {
                console.error(err);
                // showErrorMessage('Having issue to get shippers. Code: ' + err.response.data.code);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const filterOnChange = (field, value) => {
        let newFilter = { ...filter };
        newFilter[field] = value;

        setFilter({
            ...newFilter
        });
    }

    const pageOnChange = (page, pageSize) => {
        fetchShippers({
            ...filter,
            pageNumber: page,
            pageSize: pageSize
        });
    }

    const filterBtnOnClick = (e) => {
        fetchShippers({
            ...filter
        });
    }

    return (
        <div className='shippersPageWrapper'>
            <Spin spinning={loading}>
                <div className="shippersTop">
                    <Row justify='space-between'>
                        <Col>
                            <div className="shippersTitle" style={{ fontSize: '30px', fontWeight: '900' }}>SHIPPERS</div>
                        </Col>
                        <Col>
                            {/* <Button className='addButton' icon={<UserAddOutlined />} style={{ borderRadius: 10 }} /> */}
                        </Col>
                    </Row>
                </div>
                <Row className="widgetRow" gutter={[8, 8]} style={{ marginTop: '10px' }}>
                    <Widget span={24}>
                        <div className="tableWidgetWrapper">
                            <div className="tableWidgetTop">
                            </div>
                            <div className="tabelWidgetContent" >
                                <Row>
                                    <Col span={4}>
                                        <Collapse defaultActiveKey={['1']} ghost>
                                            <Collapse.Panel header="Filter" key="1">
                                                <Form.Item label='Name' labelCol={{ span: 24 }}>
                                                    <Input placeholder='Shipper Name' value={filter.name} onChange={(e) => filterOnChange('name', e.target.value)} />
                                                </Form.Item>
                                                <Form.Item label='Shipper Code' labelCol={{ span: 24 }}>
                                                    <Input placeholder='Shipper Code' value={filter.shipperCode} onChange={(e) => filterOnChange('shipperCode', e.target.value)} />
                                                </Form.Item>
                                                <Form.Item label='Branch' labelCol={{ span: 24 }}>
                                                    <Select className='branchSelect' value={filter.branch} onSelect={(v) => filterOnChange('branch', v)}>
                                                        {renderSelectOptions(options.branch)}
                                                    </Select>
                                                </Form.Item>
                                                <Row justify='end'>
                                                    <Col>
                                                        <ReloadBtn type='primary' onClick={filterBtnOnClick} loading={loading} />
                                                    </Col>
                                                </Row>
                                            </Collapse.Panel>
                                        </Collapse>
                                    </Col>
                                    <Col span={20}>
                                        <CustomTable pageSize={pagination.pageSize} currentPage={pagination.pageNumber} totalCount={pagination.total} pageOnChange={pageOnChange} items={shippers} columns={tableColumns} />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Widget>
                </Row>
            </Spin>
        </div>
    )
}

export default Shippers;