import React, { createContext, useReducer } from 'react';
import appReducer from '../reducers/appReducer';

export const AppContext = createContext();

const initAppState = {
    options: {}
};

export const AppContextProvider = props => {
    const [state, dispatch] = useReducer(appReducer, initAppState);

    return (
        <AppContext.Provider value={[state, dispatch]}>
            {props.children}
        </AppContext.Provider>
    );
};