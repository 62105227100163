import { message as antdMessage, Button, Space } from 'antd';

const showSuccessMessage = (message) => {
    antdMessage.success(message ? message : 'Success');
};

const showErrorMessage = (message) => {
    antdMessage.error(message ? message : 'Error');
};

const showErrorResponse = (data) => {
    if(data){
        if(data.message){
            antdMessage.error(data.message);
        }else if(data.code){
            console.error(`Error Code: ${data.code}`);
            antdMessage.error(`Error Code: ${data.code}`);
        }
    }else{
        antdMessage.error('Error');
    }
}

const showWarningMessage = (message) => {
    antdMessage.warning(message ? message : "Warning");
};

export { showSuccessMessage, showErrorMessage, showWarningMessage };