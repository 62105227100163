import { Alert, Button, Checkbox, Col, Divider, Form, Input, Modal, Radio, Row, Select, Skeleton, Spin, Tag, Tooltip, Upload } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams,useNavigate } from 'react-router-dom';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import { completeApplication, getApplicationById, getApplicationFileById, updateApplication, uploadApplicationFile, startReviewApplication } from '../../apis/application';
import { getUserProfile as getUserProfileApi } from '../../apis/auth';
import { showErrorMessage, showSuccessMessage, showWarningMessage } from '../../components/message';
import Widget from '../../components/widget/Widget';
import { ApplicationStatus } from '../../constants';
import { AppContext } from '../../contexts/AppContext';
import { convertOptionComponent, getFileExt } from '../../utils/helper';
import { FileSearchOutlined, UploadOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import FileDownload from 'js-file-download';
import { saveAs } from 'file-saver';

import './ApplicationView.css';
import { AuthContext } from '../../contexts/AuthContext';
import { AuthAction } from '../../reducers/authReducer';
import CountrySelect from '../../components/countrySelect/CountrySelect';
import Applications from '../applications/Applications';
import TextArea from 'antd/lib/input/TextArea';
import PaymentTermsSelect from '../../components/paymentTermSelect/PaymentTermsSelect';
import DestinationCountrySelect from '../../components/destinationCountrySelect/DestinationCountrySelect';

const ApplicationView = () => {

    const { id } = useParams()

    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);

    const [application, setApplication] = useState(null);
    const [inAction, setInAction] = useState(false);

    const [userCountry, setUserCountry] = useState("");
    const [valid, setValid] = useState(false);

    const [appState, appDispatch] = useContext(AppContext);
    const [authState, authDispatch] = useContext(AuthContext);

    const { options } = appState;
    let navigate = useNavigate();

    const [form] = Form.useForm();

    useEffect(() => {
        getUserProfile();
        getApplication(id);
    }, [id])

    useEffect(() => {

    }, [inAction])

    const setForm = (application) => {
        form.setFieldsValue({
            ...application
        })
    }
    const logoutHandler = () => {
        authDispatch({ type: 'LOGOUT' });
    }
    const getUserProfile = () => {
        getUserProfileApi()
          .then(res => {
            if (res.status === 200 && res.data.code === 10000) {
                authDispatch({ type: AuthAction.SET_USER_PROFILE, payload: res.data.payload });
                setUserCountry(res.data.payload.country.trim());
            }else{
                logoutHandler();
            }
          })
          .catch(err => {
            console.log(err);
            logoutHandler();
          })
    }
    const getApplication = (id) => {
        setLoading(true);
        getApplicationById(id)
            .then(res => {
                if (res.status === 200 && res.data) {
                    setApplication(res.data.payload);

                    setForm(res.data.payload);

                } else {
                    setApplication(null);
                }
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const downloadApplicationFile = () => {
        setDownloading(true);
        getApplicationFileById(application.id)
            .then(res => {
                console.log('download file', res.data);
                var blob = new Blob([res.data], { type: res.headers['content-type:'] });
                saveAs(blob, application.fileNameCh);
            })
            .catch(err => {
                showErrorMessage('Having issue to download application file');
            })
            .finally(() => {
                setDownloading(false);
            })
    }

    const renderServiceOptions = () => {
        return convertOptionComponent(options.service);
    }

    const renderBranchOptions = () => {
        return convertOptionComponent(options.branch);
    }

    const renderPolOptions = () => {
        return convertOptionComponent(options.portOfLoading);
    }

    const renderWeightOptions = () => {
        return convertOptionComponent(options.weight);
    }

    const renderLengthOptions = () => {
        return convertOptionComponent(options.length);
    }

    const renderCurrencyOptions = () => {
        return convertOptionComponent(options.currency);
    }

    const renderPaytermOptions = () => {
        return convertOptionComponent(options.paymentTerm)
    }

    const renderCountryOptions = () => {
        return convertOptionComponent(options.country);
    }

    const renderChannelOptions = () => {
        return convertOptionComponent(options.channel);
    }

    const renderPlatformOptions = () => {
        return convertOptionComponent(options.platform)
    }

    const renderPage = () => {
        if (!application) {
            return <Skeleton />
        } else {            
            if(userCountry != application.country && userCountry != 'HQ'){
                logoutHandler();
            }
            return (
                <Spin spinning={loading || inAction}>
                    <div className="applicationViewTop">
                        <Row justify='space-between'>
                            <Col>
                                <div className="applicationViewTitle">{application ? 'Application' : 'Application Not Found'}</div>
                            </Col>
                            {renderButton(application.statusCh)}
                        </Row>
                    </div>
                    <Row className="widgetRow" justify='center' gutter={[8, 8]} >
                        <Widget span={16}>
                            {renderWidgetContent()}
                        </Widget>
                    </Row>
                </Spin>
            );
        }
    }
    const renderButton = (status) => {
        if(status === ApplicationStatus.RECIEVE){
            return(
                <Button className='btn reviewBtn' onClick={() => startReviewOnClick(application)}>Start Review</Button>
            )
        }else if(status === ApplicationStatus.UNDER_REVIEW || status === ApplicationStatus.ON_HOLD){
            return(
                <Button className='btn editBtn'><Link to={`/dashboard/application/${application.id}/edit`}>Edit</Link></Button>
            )
        }
    }
    const startReviewOnClick = (application) => {
        let confirmAction = window.confirm(`Are you sure to review this application [${application.applicationCodeCh}]?`);
        if (confirmAction) {
            setInAction(true);
            startReviewApplication(application.id)
                .then(res => {
                    if (res.status === 200 && res.data.code === 10000) {
                        getApplication(application.id)
                    } else {
                        console.debug('Fail to review application. Code: ' + res.data.code, res.data);
                        showErrorMessage('Fail to review application. Code: ' + res.data.code);
                    }
                })
                .catch(err => {
                    console.debug('Having issue to review application', err);
                    showErrorMessage('Having issue to review application.');
                })
                .finally(() => {
                    setInAction(false);
                                            
                    navigate(`/dashboard/application/${application.id}/edit`);
                })
        } else {
            alert("Action canceled");
        }
    }
    const downloadOnClick = () => {
        if (application) {
            downloadApplicationFile();
        } else {
            showErrorMessage('No File To Downlaod');
        }
    }

    const renderWidgetContent = () => {
        if (!application) {
            return <Skeleton />
        } else {
            return (
                <div className="widgetWrapper">
                    <div className="widgetTop">
                        Application {application.applicationCodeCh}
                    </div>
                    <div>
                        <Row >
                            <Tag className={handleStatusStyle(application.statusCh)}>{application.statusCh}</Tag>
                            <Tooltip title={`Download File ${application.fileNameCh}`}>
                                <Button className='btn' type='primary' disabled={downloading} icon={<FileSearchOutlined />} onClick={downloadOnClick} />
                            </Tooltip>
                        </Row>
                    </div>
                    <div className="widgetContent" >
                        <Form form={form} >
                            <Row justify='center'>
                                <Col>
                                    <div className='appliactionInfo'>
                                        <div className='formTitle'>
                                            Basic Info
                                        </div>
                                        <Row>
                                            <Col span={5}>
                                                <Form.Item name={['isNewAccount']} className='inputLabel' label={<Tooltip title="Is this require new account?">New Account</Tooltip>} labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Checkbox disabled defaultChecked={application.isNewAccount} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={5}>
                                                <Form.Item name={['isFulfillment']} className='inputLabel' label={<Tooltip title='Does shipper need Yamato Fulfilment?'>Fulfillment</Tooltip>} labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Checkbox disabled defaultChecked={application.isFulfillment} onChange={(e) => form.setFieldsValue({ isFulfillment: e.target.checked })} />
                                                </Form.Item>
                                            </Col>
                                            <Col offset={1} span={11}>
                                                <Form.Item name={['channelCh']} className='inputLabel' label='E-Commerce Platform' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Select disabled>
                                                        {renderChannelOptions()}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['destinationCountryCh']} className='inputLabel' label='Des Country' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <DestinationCountrySelect disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['businessTypeCh']} className='inputLabel' label='Biz Type' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Select disabled>
                                                        <Select value={1}>B2C</Select>
                                                        <Select value={2}>B2B</Select>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['username']} className='inputLabel' label='Username' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input disabled className='inputValue' type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['companyCh']} className='inputLabel' label='Company Name' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input disabled className='inputValue' type='text' maxLength={50} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['firstName']} className='inputLabel' label='First Name' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input disabled className='inputValue' type='text' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['lastName']} className='inputLabel' label='Last Name' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input disabled className='inputValue' type='text' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row justify='center'>
                                <Col>
                                    <div className='applicationInfo'>
                                        <div className='formTitle'>Shipper Info</div>
                                        <Row>
                                            <Col span={11}>
                                                <Form.Item name={['shipperCh']} className='inputLabel' label='Shipper Code' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input disabled className='inputValue' type='text' maxLength={15} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['serviceTypeCh']} className='inputLabel' label='Service Type' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }}>
                                                    <Select disabled className='inputValue'>
                                                        {renderServiceOptions()}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['shipperNameCh']} className='inputLabel' label='Shipper Name' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input disabled type='text' maxLength={60} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['shipperShortNameCh']} className='inputLabel' label='Short Name' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input disabled type='text' maxLength={8} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['branchId']} className='inputLabel' label='Branch' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Select disabled className='inputValue'>
                                                        {renderBranchOptions()}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['portOfLoadingCh']} className='inputLabel' label={<Tooltip title='Port Of Loading'>POL</Tooltip>} labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Select disabled className='inputValue'>
                                                        {renderPolOptions()}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['weightUnitCh']} className='inputLabel' label='Weight Unit' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Select disabled className='inputValue'>
                                                        {renderWeightOptions()}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['currencyCh']} className='inputLabel' label='Currency' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Select disabled className='inputValue'>
                                                        {renderCurrencyOptions()}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['lengthUnitCh']} className='inputLabel' label='Length Unit' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Select disabled className='inputValue'>
                                                        {renderLengthOptions()}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['paymentCh']} className='inputLabel' label='Default Payment Term' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Select disabled className='inputValue'>
                                                        {renderPaytermOptions()}</Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['additionalPaymentsCh']} className='inputLabel' label='Additional Payment Term(s)' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <PaymentTermsSelect disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col span={5}>
                                                <Form.Item name={['volumeWeight']} className='inputLabel' label={<Tooltip title='Does the Shipper wants to see the volume weight?'>Volume Weight?</Tooltip>} labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Checkbox disabled defaultChecked={application.volumeWeight} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col >
                                    <div className='applicationInfo'>
                                        <div className='formTitle'>Address</div>
                                        <Row>
                                            <Col span={20}>
                                                <Form.Item name={['attention']} className='inputLabel' label='Attention' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['address']} className='inputLabel' label='Address' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input disabled maxLength={35} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['address2']} className='inputLabel' label='Address 2' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input disabled maxLength={35} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['city']} className='inputLabel' label='City' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input disabled maxLength={35} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['email']} className='inputLabel' label='Email' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input disabled type='email' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['state']} className='inputLabel' label='State' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input disabled maxLength={35} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['phone']} className='inputLabel' label='Telephone' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input disabled maxLength={15} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['country']} className='inputLabel' label='Country' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <CountrySelect disabled={true} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['mobile']} className='inputLabel' label='Mobile' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input disabled maxLength={15} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['zipcode']} className='inputLabel' label='Zipcode' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input disabled maxLength={5} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={11}>
                                                <Form.Item name={['homePage']} className='inputLabel' label='Shop URL' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <Input disabled maxLength={5} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name={['memoTxt']} className='inputLabel' label='Memo' labelCol={{ span: 24 }} wrapperCol={{ span: 22 }} >
                                                    <TextArea disabled />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            );
        }
    }

    const handleStatusStyle = (status) => {
        if(status === ApplicationStatus.RECIEVE){
            return('receivedColor');
        }else if(status === ApplicationStatus.COMPLETE){
            return('completedColor');
        }else if(status === ApplicationStatus.REJECTED){
            return('rejectedColor');
        }
    }

    return (
        <div className='applicationViewPageWrapper'>
            {renderPage()}
        </div>
    )
}

export default ApplicationView;