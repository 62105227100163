const appReducer = (state, action) => {
    // console.log(action);

    switch (action.type) {
        case appAction.SET_OPTIONS:
            return {
                ...state,
                options: action.payload
            }
        default:
            console.debug('app reducer UNDEFINED ACTION', action);
            return { ...state };
    }
};

export const appAction = {
    SET_OPTIONS: 'SET_OPTIONS'
}

export default appReducer;