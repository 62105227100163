import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, Select, Spin, Table, Tooltip } from 'antd';
import { EditOutlined, ShopOutlined, CloseCircleOutlined, PlusCircleOutlined, UserOutlined } from '@ant-design/icons';
import Widget from '../../components/widget/Widget';
import { useNavigate, useParams } from 'react-router-dom';

import './ShipperDetail.css';
import { addShipperChannel, getShipperById, removeShipperChannel, updateShipperAddress, updateShipperChannel, updateShipperInfo } from '../../apis/shipper';
import { showErrorMessage, showSuccessMessage, showWarningMessage } from '../../components/message';
import { AppContext } from '../../contexts/AppContext';
import { convertOptionComponent, getValue, renderSelectOptions, checkPaymentTerms } from '../../utils/helper';
import { formValidateMessages } from '../../utils/patterns';
import CountrySelect from '../../components/countrySelect/CountrySelect';
import DefaultPaymentTermSelect from '../../components/paymentTermSelect/DefaultPaymentTermSelect';
import { DefaultPaymentTerms } from '../../constants';
import PaymentTermsSelect from '../../components/paymentTermSelect/PaymentTermsSelect';
import { PaymentTerms } from '../../constants';
import DestinationCountrySelect from '../../components/destinationCountrySelect/DestinationCountrySelect';

const ShipperDetail = () => {

    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [shipper, setShipper] = useState(null);
    const [shipperInfoModalVisible, setShipperInfoModalVisible] = useState(false);
    const [shipperAddressModalVisible, setShipperAddressModalVisible] = useState(false);
    const [newChannelModalVisible, setNewChannelModalVisible] = useState(false);
    const [updateAddressConfirmModalVisible, setupdateAddressConfirmModalVisible] = useState(false);
    const [selectedRemoveChannel, setSelectedRemoveChannel] = useState(null);
    const [selectedUpdateChannel, setSelectedUpdateChannel] = useState(null);

    const { id } = useParams();
    let navigate = useNavigate();

    const [appState, appDispatch] = useContext(AppContext);
    const { options } = appState;

    const [shipperInfoUpdateForm] = Form.useForm();
    const [shipperAddressUpdateForm] = Form.useForm();
    const [newChannelForm] = Form.useForm();
    const [updateChannelForm] = Form.useForm();

    const channelColumn = [
        {
            title: 'Channel',
            dataIndex: 'channelId',
            key: 'channelId',
            render: (t, r) => {
                return getChannelName(r.channelId);
            }
        },
        {
            title: 'Shipper Code',
            dataIndex: 'shipperCodeCh',
            key: 'shipperCodeCh',
            render: (t, r) => {
                return (
                    <>
                        {t}
                        <Tooltip className='tableBtn' title='Update Shipper Code'><Button icon={<EditOutlined />} onClick={() => selectChannel(r.channelId)} /></Tooltip>
                    </>
                )
            }
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (t, r) => {
                return (
                    <>
                        <Tooltip className='tableBtn' title='Check Shop'><Button icon={<ShopOutlined />} onClick={() => openHomepage()}/></Tooltip>
                        <Tooltip className='tableBtn' title='Remove'><Button icon={<CloseCircleOutlined />} onClick={() => setSelectedRemoveChannel(r.channelId)} /></Tooltip>
                    </>
                )
            }
        }
    ]

    const userColumn = [
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (t, r) => {
                return (
                    <>
                        <Tooltip className='tableBtn' title='Check User'><Button icon={<UserOutlined />} onClick={() => navigate(`/dashboard/user/${r.id}`)} /></Tooltip>
                    </>
                )
            }
        }
    ]

    useEffect(() => {
        fetchShipperById(id);
    }, []);

    const fetchShipperById = (id) => {
        if (id) {
            setLoading(true)
            getShipperById(id)
                .then(res => {
                    if (res.status === 200 && res.data.code === 10000) {
                        setShipper(res.data.payload);
                    } else {
                        console.log('Fail to get shipper', res.data);
                        showErrorMessage('Fail to get shipper');
                        setShipper(null);
                    }
                })
                .catch(err => {
                    console.error('Having issue to get shipper', err);
                    showErrorMessage('Fail to get shipper');
                    setShipper(null);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const updateAddressBtnOnClick = (e) => {
        setupdateAddressConfirmModalVisible(true);
    }

    const updateAddressConfirmOnClose = (e) => {
        setupdateAddressConfirmModalVisible(false);
    }

    const openHomepage = () => {
        if(shipper.address.homepage.trim() != ''){
            window.open(`https://${shipper.address.homepage}`);
        }
    }

    const selectChannel = (channel) => {
        setSelectedUpdateChannel(channel);
        updateChannelForm.setFieldsValue({
            shipperCode: '',
            channel: channel
        })
    }

    const updateAddressConfirmSubmit = (e) => {
        shipperAddressUpdateForm.submit()
        setupdateAddressConfirmModalVisible(false);
        setShipperAddressModalVisible(false);
    }

    const renderBranch = (branchId) => {
        if (options.branch) {
            return options.branch.find(b => b.value === branchId).name;
        } else {
            return <></>
        }
    }

    const renderdefaultPayment = (defaultPaymentTermCh) => {
        if (defaultPaymentTermCh) 
        {
            for(var i in DefaultPaymentTerms){
                if(defaultPaymentTermCh == i)
                {
                    var defaultPaymentTerm = DefaultPaymentTerms[i];
                }
            }
            return defaultPaymentTerm;
        } 
        else 
        {
            return <></>
        }
    }

    const renderPayment = (initialPaymentTerms) => {
        if (initialPaymentTerms) {
            return initialPaymentTerms.split(",").sort().filter(payment => checkPaymentTerms(payment)).map(payment => PaymentTerms[payment]).join(", ");
        }
        else
        {
            return <></>;
        }
    }

    const getChannelName = (channelId) => {

        if (!channelId) {
            return '';
        }

        if (options.channel) {
            return options.channel.find(c => c.value === channelId).name;
        } else {
            return '';
        }
    }

    const shipperInfoEditBtnOnClick = (e) => {
        shipperInfoUpdateForm.setFieldsValue({
            shipperName: shipper.shipperNameCh,
            shipperCode: shipper.shipperCh,
            defaultPaymentTerm: shipper.defaultPaymentTermCh,
            paymentTerms: shipper.paymentTermsCh,
            destinationCountry: shipper.destinationCountryCh
        })
        setShipperInfoModalVisible(true);
    }

    const shipperAddressEditBtnOnClick = (e) => {
        shipperAddressUpdateForm.setFieldsValue({
            addressId: shipper.addressId,
            attention: getValue(shipper.address.attention).trim(),
            address: getValue(shipper.address.address).trim(),
            address2: getValue(shipper.address.address2).trim(),
            city: getValue(shipper.address.city).trim(),
            state: getValue(shipper.address.state).trim(),
            country: getValue(shipper.address.country).trim(),
            zipcode: getValue(shipper.address.zipcode).trim(),
            phone: getValue(shipper.address.phone).trim(),
            mobile: getValue(shipper.address.mobile).trim(),
            email: getValue(shipper.address.email).trim(),
        })
        setShipperAddressModalVisible(true);
    }

    const shipperInfoEditClose = () => {
        shipperInfoUpdateForm.resetFields();
        setShipperInfoModalVisible(false);
    }

    const newChannelModalOnClose = () => {
        newChannelForm.resetFields();
        setNewChannelModalVisible(false);
    }

    const removeChannelModalOnClose = () => {
        setSelectedRemoveChannel(null);

    }

    const updateChannelModalOnClose = () => {
        setSelectedUpdateChannel(null);
        updateChannelForm.resetFields();
    }

    const shipperAddressEditOnClose = () => {
        shipperAddressUpdateForm.resetFields();
        setShipperAddressModalVisible(false);
    }

    const shipperInfoUpdateOnSubmit = (f) => {
        if (!f.shipperName || !f.shipperCode) {
            showWarningMessage('Please enter valid value.');
            return;
        }

        setUpdating(true);
        updateShipperInfo(id, f)
            .then(res => {
                if (res.status === 200 && res.data.code === 10000) {
                    setShipper(res.data.payload);
                    setShipperInfoModalVisible(false);
                } else {
                    console.log('Fail to update shipper. Message: ' + res.data.message);
                }
            })
            .catch(err => {
                console.log('Fail to update shipper. Message: ' + err.response.data.message);
            })
            .finally(() => {
                setUpdating(false);
            })

    }

    const newChannelOnSubmit = (f) => {
        setUpdating(true);
        addShipperChannel(shipper.id, f)
            .then(res => {
                if (res.status === 200 && res.data.code === 10000) {
                    setNewChannelModalVisible(false);
                    showSuccessMessage('Success');
                    setShipper(res.data.payload);
                } else {
                    showErrorMessage('Fail to add new channel. Message: ' + res.data.message);
                }
            })
            .catch(err => {
                showErrorMessage('Having issue to add new channel');
                console.log(err);
            })
            .finally(() => {
                setUpdating(false);
            })
    }

    const updateChannelOnSubmit = (f) => {

        setUpdating(true);
        updateShipperChannel(shipper.id, { ...f, channel: selectedUpdateChannel })
            .then(res => {
                if (res.status === 200 && res.data.code === 10000) {
                    setSelectedUpdateChannel(null);
                    showSuccessMessage('Success');
                    setShipper(res.data.payload);
                } else {
                    showErrorMessage('Fail to update channel. Message: ' + res.data.message);
                }
            })
            .catch(err => {
                showErrorMessage('Having issue to updatechannel');
                console.log(err);
            })
            .finally(() => {
                setUpdating(false);
            });
    }

    const removeChannelOnClick = () => {
        setUpdating(true);
        removeShipperChannel(shipper.id, selectedRemoveChannel)
            .then(res => {
                if (res.status === 200 && res.data.code === 10000) {
                    setSelectedRemoveChannel(null);
                    showSuccessMessage('Success');
                    setShipper(res.data.payload);
                } else {
                    showErrorMessage('Fail to remove channel. Message: ' + res.data.message);
                }
            })
            .catch(err => {
                showErrorMessage('Having issue to remove channel');
                console.log(err);
            })
            .finally(() => {
                setUpdating(false);
            })
    }

    const shipperAddressUpdateOnSubmit = (f) => {

        setUpdating(true);
        updateShipperAddress(id, f)
            .then(res => {
                if (res.status === 200 && res.data.code === 10000) {
                    showSuccessMessage('success');
                    setShipper(res.data.payload);
                    setShipperAddressModalVisible(false);
                } else {
                    console.log('Fail to update shipper. Message: ' + res.data.message);
                    showWarningMessage('Fail. Message: ' + res.data.message);
                }
            })
            .catch(err => {
                console.log('Having issue update shipper. Message: ' + err.response.data.message);
                showWarningMessage('Having issue');
            })
            .finally(() => {
                setUpdating(false);
            });
    }

    const addChannelBtnOnClick = () => {
        setNewChannelModalVisible(true);
        newChannelForm.setFieldsValue({
            channel: '',
            shipperCode: ''
        })
    }

    const renderContent = () => {
        if (shipper) {
            return (
                <>
                    <Row justify='center' className="widgetRow" gutter={[8, 8]} style={{ marginTop: '10px' }}>
                        <Widget span={20} >
                            <div className="shipperWidgetWrapper">
                                <div className="tableWidgetTop">
                                    <Row justify='space-between'>
                                        <Col>
                                            <div className='widgetTitle'>
                                                Shipper Info
                                            </div>
                                        </Col>
                                        <Col>
                                            <Tooltip title='Update Info'>
                                                <Button className='widgetBtn' icon={<EditOutlined />} onClick={shipperInfoEditBtnOnClick} />
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="shipperWidgetContent" >
                                    <Row>
                                        <Col span={11}>
                                            <Form.Item label='Shipper Name' >
                                                {shipper.shipperNameCh}
                                            </Form.Item>
                                            <Form.Item label='Short Name' >
                                                {shipper.shipperShortName}
                                            </Form.Item>
                                        </Col>
                                        <Col offset={1} span={11}>
                                            <Form.Item label='Shipper Code' >
                                                {shipper.shipperCh}
                                            </Form.Item>
                                            <Form.Item label='Service Type' >
                                                {shipper.serviceType}
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item label='Branch' >
                                                {renderBranch(shipper.branchId)}
                                            </Form.Item>
                                            <Form.Item label='Length' >
                                                {shipper.defaultLengthUnitCh}
                                            </Form.Item>
                                            <Form.Item label='Shop URL' >
                                                {shipper.address.homepage}
                                            </Form.Item>
                                            <Form.Item label='Additional Payment Term(s) To Display' >
                                                {renderPayment(shipper.paymentTermsCh)}
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item label='Port' >
                                                {shipper.postOfLoadingCh}
                                            </Form.Item>
                                            <Form.Item label='Weight' >
                                                {shipper.defaultWeightUnitCh}
                                            </Form.Item>
                                            <Form.Item label='Default Payment Term' >
                                                {renderdefaultPayment(shipper.defaultPaymentTermCh)}
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item label='Destination Countries' >
                                                {shipper.destinationCountryCh.split(',').join(', ')}
                                            </Form.Item>
                                            <Form.Item label='Currency' >
                                                {shipper.defaultCurrencyCh}
                                            </Form.Item>
                                        </Col>
                                        
                                    </Row>
                                </div>
                            </div>
                        </Widget>
                        <Widget span={20}>
                            <div className="shipperWidgetWrapper">
                                <div className="tableWidgetTop">
                                    <Row justify='space-between'>
                                        <Col>
                                            <div className='widgetTitle'>
                                                Address
                                            </div>
                                        </Col>
                                        <Col>
                                            <Tooltip title='Update Address'>
                                                <Button className='widgetBtn' icon={<EditOutlined />} onClick={shipperAddressEditBtnOnClick} />
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="shipperWidgetContent" >
                                    <Row>
                                        <Col span={11}>
                                            <Form.Item label='Attention' >
                                                {shipper.address.attention}
                                            </Form.Item>
                                            <Form.Item label='Address' >
                                                {shipper.address.address}
                                            </Form.Item>
                                            <Form.Item label='Address2' >
                                                {shipper.address.address2}
                                            </Form.Item>
                                            <Form.Item label='City' >
                                                {shipper.address.city}
                                            </Form.Item>
                                            <Form.Item label='State' >
                                                {shipper.address.state}
                                            </Form.Item>
                                            <Form.Item label='Country' >
                                                {shipper.address.country}
                                            </Form.Item>
                                        </Col>
                                        <Col offset={1} span={11}>
                                            <Form.Item label='Zipcode' >
                                                {shipper.address.zipcode}
                                            </Form.Item>
                                            <Form.Item label='Phone' >
                                                {shipper.address.phone}
                                            </Form.Item>
                                            <Form.Item label='Mobile' >
                                                {shipper.address.mobile}
                                            </Form.Item>
                                            <Form.Item label='Email' >
                                                {shipper.address.email}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Widget>
                        <Widget span={10}>
                            <div className="shipperWidgetWrapper">
                                <div className="tableWidgetTop">
                                    <Row justify='space-between'>
                                        <Col>
                                            <div className='widgetTitle'>
                                                Channels
                                            </div>
                                        </Col>
                                        <Col>
                                            <Tooltip title='Add Channel'>
                                                <Button className='widgetBtn' icon={<PlusCircleOutlined />} onClick={addChannelBtnOnClick} disabled={updating} />
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="shipperWidgetContent" >
                                    <Table columns={channelColumn} dataSource={shipper.channelShipperShopTs ?? []} />
                                </div>
                            </div>
                        </Widget>
                        <Widget span={10}>
                            <div className="shipperWidgetWrapper">
                                <div className="tableWidgetTop">
                                    <Row justify='space-between'>
                                        <Col>
                                            <div className='widgetTitle'>
                                                Users
                                            </div>
                                        </Col>
                                        <Col>
                                            {/* <Tooltip title='Add Users'>
                                                <Button className='widgetBtn' icon={<PlusCircleOutlined />} />
                                            </Tooltip> */}
                                        </Col>
                                    </Row>
                                </div>
                                <div className="shipperWidgetContent" >
                                    <Table columns={userColumn} dataSource={shipper.users ?? []} />
                                </div>
                            </div>
                        </Widget>
                    </Row>
                </>
            )
        } else {
            <div>
                <h2>Shipper Not Found</h2>
            </div>
        }
    }

    return (
        <div className='shipperDetailPageWrapper'>
            <Spin spinning={loading || updating}>
                <div className="shipperDetailTop">
                    <Row justify='space-between'>
                        <Col>
                            <div className="shipperDetailTitle" style={{ fontSize: '30px', fontWeight: '900' }}>SHIPPER - {shipper?.shipperNameCh}</div>
                        </Col>
                        <Col>

                        </Col>
                    </Row>
                </div>
                {renderContent()}
                <Modal title='Update Shipper' visible={shipperInfoModalVisible} footer={[
                    <Button className='btn' type='primary' onClick={() => shipperInfoUpdateForm.submit()} >Save</Button>
                ]} onCancel={shipperInfoEditClose}>
                    <Form onFinish={shipperInfoUpdateOnSubmit} form={shipperInfoUpdateForm} validateMessages={formValidateMessages} >
                        <Form.Item label='Shipper Name' name={['shipperName']} rules={[{ required: true }]}>
                            <Input maxLength={60} />
                        </Form.Item>
                        <Form.Item label='Shipper Code' name={['shipperCode']} rules={[{ required: true }]}>
                            <Input maxLength={15} />
                        </Form.Item>
                        <Form.Item label='Default Payment Term' name={['defaultPaymentTerm']} rules={[{ required: true }]}>
                            <DefaultPaymentTermSelect />
                        </Form.Item>
                        <Form.Item label='Additional Payment Term(s) To Be Included' name={['paymentTerms']} rules={[{ required: false }]}>
                            <PaymentTermsSelect />
                        </Form.Item>
                        <Form.Item label='Destination Countries' name={['destinationCountry']} rules={[{ required: false }]}>
                            <DestinationCountrySelect />
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal title='Update Shipper Address' visible={shipperAddressModalVisible} footer={[
                    <Button disabled={updating} className='btn' type='primary' onClick={updateAddressBtnOnClick} >Save</Button>
                ]} onCancel={shipperAddressEditOnClose}>
                    <Form onFinish={shipperAddressUpdateOnSubmit} form={shipperAddressUpdateForm} validateMessages={formValidateMessages} >
                        <Form.Item label='Address ID' name={['addressId']} hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item label='Attention' name={['attention']} rules={[{ required: true }]}>
                            <Input maxLength={30} />
                        </Form.Item>
                        <Form.Item label='Address' name={['address']} rules={[{ required: true }]}>
                            <Input maxLength={35} />
                        </Form.Item>
                        <Form.Item label='Address2' name={['address2']}>
                            <Input maxLength={35} />
                        </Form.Item>
                        <Form.Item label='City' name={['city']} rules={[{ required: true }]}>
                            <Input maxLength={35} />
                        </Form.Item>
                        <Form.Item label='State' name={['state']} rules={[{ required: true }]}>
                            <Input maxLength={35} />
                        </Form.Item>
                        <Form.Item label='Country' name={['country']} rules={[{ required: true }]}>
                            <CountrySelect addition={[{value: 'JP', name:'Japan'}]} />
                        </Form.Item>
                        <Form.Item label='Zipcode' name={['zipcode']} rules={[{ required: true }]}>
                            <Input maxLength={10} />
                        </Form.Item>
                        <Form.Item label='Phone' name={['phone']} rules={[{ required: true }]}>
                            <Input maxLength={20} />
                        </Form.Item>
                        <Form.Item label='Mobile' name={['mobile']} >
                            <Input maxLength={15} />
                        </Form.Item>
                        <Form.Item label='Email' name={['email']} rules={[{ required: true, type: 'email' }]}>
                            <Input maxLength={60} />
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                        title="Update Address?"
                        visible={updateAddressConfirmModalVisible}
                        onCancel={updateAddressConfirmOnClose}
                        footer={[
                            <Button className='btn' type='primary' disabled={updating} onClick={updateAddressConfirmSubmit}>Yes</Button>]}>
                        <p>If you update this shipper's address, it will update their all their un-transmitted shipment data automatically in Y-Ship Cloud.</p>
                        <p>and please update shipper information in KTE.</p>
                        <p>Do you want to update the address?</p>
                </Modal>

                <Modal title='Add Channel' visible={newChannelModalVisible} footer={[
                    <Button className='btn' type='primary' onClick={() => newChannelForm.submit()} disabled={updating}>Save</Button>
                ]} onCancel={newChannelModalOnClose}>
                    <Form onFinish={newChannelOnSubmit} form={newChannelForm} validateMessages={formValidateMessages} >
                        <Form.Item label='Channel' name={['channel']} rules={[{ required: true }]}>
                            <Select>
                                {options && options.channel && shipper ? renderSelectOptions(options.channel.filter(c => !shipper.channelShipperShopTs.map(cs => cs.channelId).includes(c.value))) : []}
                            </Select>
                        </Form.Item>
                        <Form.Item label='Shipper Code' name={['shipperCode']} rules={[{ required: true }]}>
                            <Input maxLength={15} />
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal title='Remove Channel' visible={selectedRemoveChannel} footer={[
                    <Button className='btn' type='primary' onClick={removeChannelOnClick} disabled={updating} >Remove</Button>
                ]} onCancel={removeChannelModalOnClose}>
                    <p>Do you want to remove {getChannelName(selectedRemoveChannel)} channel?</p>
                </Modal>

                <Modal title='Update Channel Shipper Code' visible={selectedUpdateChannel} footer={[
                    <Button className='btn' type='primary' onClick={() => updateChannelForm.submit()} disabled={updating}>Save</Button>
                ]} onCancel={updateChannelModalOnClose}>
                    <Form onFinish={updateChannelOnSubmit} form={updateChannelForm} validateMessages={formValidateMessages} >
                        <Form.Item label='Shipper Code' name={['shipperCode']} rules={[{ required: true }]}>
                            <Input maxLength={15} />
                        </Form.Item>
                        <Form.Item hidden label='Channel' name={['channel']} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Form>
                </Modal>
            </Spin>
        </div >
    )
}

export default ShipperDetail;