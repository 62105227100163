import { Col, Input, Row, Table, Spin, Button, Modal, Form, InputNumber, Select, Tooltip, Checkbox, Dropdown } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import { DownOutlined, UserAddOutlined, SyncOutlined, CheckCircleOutlined, StopOutlined, RedoOutlined, CopyOutlined, EditOutlined } from '@ant-design/icons';
import Widget from '../../components/widget/Widget';
import { getStaffs, registerStaff, updatePassword, updateStaff } from '../../apis/staff';
import { showErrorMessage, showSuccessMessage, showWarningMessage } from '../../components/message';
import moment from 'moment';
import './Staffs.css';
import { getUserProfile as getUserProfileApi } from '../../apis/auth';
import { convertOptionComponent, getRandomPassword, getRandonString, renderSelectOptions, validateEmail } from '../../utils/helper';
import { getGroupOptions } from '../../apis/group';
import { getBranchOptions } from '../../apis/staff'
import { activateUser, deactivateUser } from '../../apis/staff';
import CustomTable from '../../components/CustomTable/CustomTable';
import { formValidateMessages, pattern } from '../../utils/patterns';
import { AuthAction } from "../../reducers/authReducer";
import { AuthContext } from "../../contexts/AuthContext";

const Staffs = () => {

    const [loading, setLoading] = useState(false);
    const [loadingGroupOptions, setLoadingGrouOptions] = useState(false);
    const [loadingBranchOptions, setLoadingBranchOptions] = useState(false);
    const [submiting, setSubmiting] = useState(false);
    const [staffs, setStaffs] = useState([]);
    const [filteredStaffs, setFilteredStaffs] = useState([])
    const [selectedActivateUser, setSelectedActivateUser] = useState();
    const [selectedResetPasswordUser, setSelectedResetPasswordUser] = useState(null);
    const [selectedUpdateStaff, setSelectedUpdateStaff] = useState(null);
    const [activatingUser, setActivatingUser] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [groupOptions, setGroupOptions] = useState([]);
    const [branchOptions, setBranchOptions] = useState([]);
    const [isNewUserModalVisible, setIsNewUserModalVisible] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [authState, authDispatch] = useContext(AuthContext);

    var [newUserForm] = Form.useForm();
    var [staffUpdateForm] = Form.useForm();

    const tableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (t, r) => `${r.firstName} ${r.lastName}`
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Branch',
            dataIndex: 'branchId',
            key: 'branchId',
            render:(t, r) =>  
            {
                let branchChosen = branchOptions.filter((obj) => obj.value === t);
                if (branchChosen[0])
                {
                    return branchChosen[0]["name"];
                }
                else
                {
                    return r;
                }
            }   
        },
        {
            title: 'Superuser',
            dataIndex: 'isSuperuser',
            key: 'isSuperuser',
            render: (t, r) => {
                if (r.isSuperuser) {
                    return <DownOutlined />
                }
            }
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country'  ,
            render:(t, r) => `${r.country}`
              
        },
        {
            title: 'Active',
            dataIndex: 'isActive',
            key: 'isActive',
            width: 10,
            render: (t, r) => {
                if (r.isActive) {
                    return <DownOutlined />
                }
            }
        },
        {
            title: 'Last Login',
            dataIndex: 'lastLogin',
            key: 'lastLogin',
            width: 100,
            render: (t, r) => t ? moment(t).format('MM/DD/YYYY') : ''
        },
        {
            title: '',
            render: (t, r) => {
                let actions = []

                actions.push(<Tooltip key={1} title='Update'><Button className='tableBtn editBtn' icon={<EditOutlined />} onClick={(e) => editStaffBtnOnClick(r)} /></Tooltip>);

                if (r.isActive) {
                    actions.push(<Tooltip key={2} title='Deactivate'><Button className='tableBtn deactivateBtn' icon={<StopOutlined />} onClick={(e) => setSelectedActivateUser({ userId: r.id, activate: false })} /></Tooltip>);
                } else {
                    actions.push(<Tooltip key={2} title='Activate'><Button className='tableBtn activateBtn' icon={<CheckCircleOutlined />} onClick={(e) => setSelectedActivateUser({ userId: r.id, activate: true })} /></Tooltip>);
                }

                actions.push(<Tooltip key={3} title='Reset Password'><Button className='tableBtn resetBtn' icon={<RedoOutlined />} onClick={(e) => setSelectedResetPasswordUser({ user: r, password: getRandomPassword() })} /></Tooltip>)

                return actions;
            }
        }
    ]

    useEffect(() => {
        fetchStaffs()
    }, [])

    useEffect(() => {
        fetchBranchOptions()
    }, [])
    useEffect(() => {
        setFilteredStaffs([...staffs.filter(s => s.firstName.includes(searchText) || s.username.includes(searchText))]);
    }, [staffs, searchText])

    const getUserProfile = () => {
        getUserProfileApi()
          .then(res => {
            if (res.status === 200 && res.data.code === 10000) {
              authDispatch({ type: AuthAction.SET_USER_PROFILE, payload: res.data.payload });
            } else {
              logoutHandler();
            }
          })
          .catch(err => {
            logoutHandler();
          })
      }
    const logoutHandler = () => {
        authDispatch({ type: 'LOGOUT' });
    }
    const fetchStaffs = () => {
        setLoading(true);
        getStaffs()
            .then(res => {
                if (res.status === 200 && res.data) {
                    setStaffs(res.data.payload);
                }
            }).catch(err => {
                console.error(err);
                showErrorMessage('Fail to get staffs.')
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const fetchGroupOptions = () => {
        setLoadingGrouOptions(true);
        getGroupOptions()
            .then((res) => {
                if (res.status === 200 && res.data.code === 10000) {
                    setGroupOptions(res.data.payload);
                } else {
                    setGroupOptions([]);
                }
            })
            .catch(err => {
                setGroupOptions([]);
            })
            .finally(() => {
                setLoadingGrouOptions(false);
            });
    }
    const fetchBranchOptions = () => {
        setLoadingBranchOptions(true);
        getBranchOptions()
        .then((res) => {
            if (res.status === 200 && res.data.code === 10000){
                setBranchOptions(res.data.payload);
            } else {
                setBranchOptions([]);
            }
        })
        .catch(err => {
            setBranchOptions([]);
        })
        .finally(() => {
            setLoadingBranchOptions(false);
        });
        
    }

    const newUserModalHandleCancel = () => {
        newUserForm.resetFields();
        setIsNewUserModalVisible(false);
    }

    const openNewUserModal = (e) => {
        fetchGroupOptions();
        fetchBranchOptions();
        setIsNewUserModalVisible(true);
    }

    const renderOptions = (options) => {
        return renderSelectOptions(options)
    }

    const newUserOnSubmit = (newUser) => {

        // console.log(newUser);

        setSubmiting(true);
        if (newUser) {
            registerStaff(newUser)
                .then(res => {
                    if (res.status === 200 && res.data.code === 10000) {
                        showSuccessMessage('Success');
                        newUserModalHandleCancel();
                        fetchStaffs();
                    } else {
                        showErrorMessage('Fail to create new user. Code: ' + res.data.code);
                    }
                })
                .catch(err => {
                    showErrorMessage('Fail to create new user');
                    console.error(err);
                })
                .finally(() => {
                    setSubmiting(false);
                })
        } else {
            showWarningMessage('New user information cannot be empty');
        }
    };

    const newUserModalClose = () => {
        newUserForm.resetFields();
    }

    const newUserFormValidateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
        },
    };

    const activateModalOnClose = () => {
        setSelectedActivateUser(null);
    }

    const resetPasswordModalOnClose = () => {
        setSelectedResetPasswordUser(null);
    }

    const newPasswordModalOnClose = () => {
        setNewPassword(null);
    }

    const activeBtnOnClick = () => {
        if (selectedActivateUser) {
            // activate user
            if (selectedActivateUser.activate) {
                setActivatingUser(true);
                activateUser(selectedActivateUser.userId)
                    .then(res => {
                        if (res.status === 200 && res.data.code === 10000) {
                            setSelectedActivateUser(null);
                            showSuccessMessage('Success');
                            fetchStaffs();
                        }
                    })
                    .catch(err => {
                        showErrorMessage('Having issue to activate user');
                    })
                    .finally(() => {
                        setActivatingUser(false);
                    });

            } else {
                // deactivate user
                setActivatingUser(true);
                deactivateUser(selectedActivateUser.userId)
                    .then(res => {
                        if (res.status === 200 && res.data.code === 10000) {
                            showSuccessMessage('Success');
                            fetchStaffs();
                        }
                    })
                    .catch(err => {
                        showErrorMessage('Having issue to activate user');
                    })
                    .finally(() => {
                        setActivatingUser(false);
                    });
            }
        }
    }

    const resetPasswordBtnOnClick = () => {
        if (selectedResetPasswordUser) {
            setUpdating(true);
            updatePassword(selectedResetPasswordUser.user.id, selectedResetPasswordUser.password)
                .then(res => {
                    if (res.status === 200 && res.data.code === 10000) {
                        showSuccessMessage('Success');
                        setSelectedResetPasswordUser(null);
                        setNewPassword(selectedResetPasswordUser.password);
                    }
                })
                .catch(err => {
                    showErrorMessage('Having issue to reset user password. Message: ' + err.response.data.payload);
                })
                .finally(() => {
                    setUpdating(false);
                });
        }
    }

    const editStaffBtnOnClick = (u) => {
        setSelectedUpdateStaff(u);        
        fetchBranchOptions();
        staffUpdateForm.setFieldsValue({
            id: u.id,
            username: u.username,
            firstName: u.firstName,
            lastName: u.lastName,
            email: u.email,
            isSuperuser: u.isSuperuser,
            branchId: u.branchId,
            branch: u.branch,
        })
    }

    const updateStaffOnCancel = () => {
        staffUpdateForm.resetFields();
        setSelectedUpdateStaff(null);
    }

    const updateUserOnClick = () => {
        staffUpdateForm.submit();
    }

    const updateUserOnSubmit = (f) => {
        console.log('update staff', f);
        setUpdating(true);
        updateStaff(f.id, f)
            .then(res => {
                if(res.status === 200 && res.data.code === 10000){
                    showSuccessMessage();
                    setSelectedUpdateStaff(null);
                    fetchStaffs();
                }
            })
            .catch(err => {
                showErrorMessage('Failed to update user');
                console.error('Fail to update user', err);
            })
            .finally(() => {
                setUpdating(false);
            })
    }

    const generatePasswordBtnOnClick = (e) => {
        var newPassword = getRandomPassword();
        console.log('password', newPassword);
        newUserForm.setFieldsValue({
            password: newPassword,
            passwordConfirm: newPassword
        })
    }
    const renderPage = () => {
        if(authState.user && authState.user.country.trim() == 'HQ'){
            return(
                <>
                <Modal title="New User"
                    visible={isNewUserModalVisible}
                    onCancel={newUserModalHandleCancel}
                    footer={[
                        <Button className='btn' type='primary' onClick={() => newUserForm.submit()} disabled={submiting}>
                            {submiting ? <Spin spinning /> : 'Submit'}
                        </Button>
                    ]}>
                    <Form form={newUserForm} labelCol={{ span: 8 }} name="nest-messages" onFinish={newUserOnSubmit} validateMessages={newUserFormValidateMessages}>
                        <Form.Item name={['username']} label="Username" rules={[{ required: true, pattern: pattern.email }]}>
                            <Input autoComplete='off' />
                        </Form.Item>
                        <Row justify='end'>
                            <Col>
                                <Button onClick={generatePasswordBtnOnClick} className='btn'>Generate Password</Button>
                            </Col>
                        </Row>
                        <Form.Item
                            name={['password']}
                            label={<Tooltip title='your password must at least 8 characters and contain: 1 lower case charater, 1 upper case character, 1 special character: !@#$%^&*'>Password</Tooltip>}
                            rules={[{ required: true, pattern: pattern.password, message: 'password is not valid' }]}
                        >
                            <Input.Password autoComplete='off' />
                        </Form.Item>
                        <Form.Item name={['passwordConfirm']} label="Confirm" rules={[{ required: true, pattern: pattern.password, message: 'password is not valid' }]}>
                            <Input.Password autoComplete='off' />
                        </Form.Item>
                        <Form.Item name={['firstName']} label="First Name" rules={[{ required: true }]}>
                            <Input autoComplete='off' />
                        </Form.Item>
                        <Form.Item name={['lastName']} label="Last Name" rules={[{ required: true }]}>
                            <Input autoComplete='off' />
                        </Form.Item>
                        <Form.Item name={['email']} label="Email" rules={[{ required: true, type: 'email' }]}>
                            <Input autoComplete='off' />
                        </Form.Item>
                        <Form.Item name={['group']} label="Group" rules={[{required: true}]} >
                            <Select disabled={loadingGroupOptions} onSelect={(v) => { newUserForm.setFieldsValue({ group: v }) }} >
                                {renderOptions(groupOptions)}
                            </Select>
                            <Button icon={<SyncOutlined />} onClick={(e) => fetchGroupOptions()} />
                        </Form.Item>
                        <Form.Item name={['country']} label="Branch" rules={[{ required: true}]}>
                            <Select disabled={loadingBranchOptions} onSelect={(v) => { newUserForm.setFieldsValue({ branch: v }) }} >
                                {renderOptions(branchOptions)}
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
                <Spin spinning={loading}>
                    <div className="staffsTop">
                        <Row justify='space-between'>
                            <Col>
                                <div className="staffsTitle" style={{ fontSize: '30px', fontWeight: '900' }}>STAFFS</div>
                            </Col>
                            <Col>
                                <Button className='btn' type='primary' onClick={openNewUserModal} icon={<UserAddOutlined />} style={{ borderRadius: 10 }} />
                            </Col>
                        </Row>
                    </div>
                    <Row className="widgetRow" justify='center' gutter={[8, 8]} style={{ marginTop: '10px' }}>
                        <Widget span={20}>
                            <div className="tableWidgetWrapper">
                                <div className="tableWidgetTop">
                                </div>
                                <div className="tabelWidgetContent" >
                                    <Row justify='end'>
                                        <Input
                                            style={{ width: 200 }}
                                            placeholder='Search Name'
                                            onChange={(e) => setSearchText(e.target.value)}
                                        />
                                    </Row>
                                    {/* <Table pagination={{ position: ['topRight'], size: 'small' }} dataSource={filteredStaffs} columns={tableColumns} /> */}
                                    <CustomTable pageSize={5} items={filteredStaffs} columns={tableColumns} />
                                </div>
                            </div>
                        </Widget>
                    </Row>

                    <Modal
                        title="Update Staff"
                        visible={selectedUpdateStaff}
                        onCancel={updateStaffOnCancel}
                        footer={[
                            <Button type='primary' disabled={updating} className='btn' onClick={updateUserOnClick}>Update</Button>
                        ]}
                    >
                        <Form 
                            form={staffUpdateForm}
                            onFinish={updateUserOnSubmit}
                            validateMessages={formValidateMessages}
                            >
                            <Form.Item name={['id']} hidden={true}>
                            </Form.Item>
                            <Form.Item name={['username']} label="Username" rules={[{ required: true, pattern: pattern.username }]}>
                                <Input autoComplete='off' />
                            </Form.Item>
                            <Form.Item name={['email']} label="Email" rules={[{ required: true, type: 'email' }]}>
                                <Input autoComplete='off' />
                            </Form.Item>
                            <Form.Item name={['firstName']} label="First Name" rules={[{ required: true }]}>
                                <Input autoComplete='off' />
                            </Form.Item>
                            <Form.Item name={['lastName']} label="Last Name" rules={[{ required: true }]}>
                                <Input autoComplete='off' />
                            </Form.Item>
                            <Form.Item name={['branchId']} label="Branch" rules={[{ required: true}]}>
                                <Select disabled={loadingBranchOptions} onSelect={(v) => { newUserForm.setFieldsValue({ branchId: v }) }} >
                                    {renderOptions(branchOptions)}
                                </Select>
                            </Form.Item>
                            <Form.Item name={['isSuperuser']} label="Super User?">
                                {/* <Input type='checkbox' autoComplete='off' /> */}
                                <Checkbox onChange={e => {staffUpdateForm.setFieldsValue({isSuperuser: e.target.checked})}} />
                            </Form.Item>
                            
                        </Form>

                    </Modal>

                    <Modal
                        visible={selectedActivateUser}
                        onCancel={activateModalOnClose}
                        footer={[<Button type='primary' disabled={activatingUser} className='btn' onClick={activeBtnOnClick}>Yes</Button>]} >
                        {selectedActivateUser?.activate ? 'Do you want to activate this user?' : 'Do you want to deactivate this user?'}
                    </Modal>

                    <Modal
                        visible={selectedResetPasswordUser}
                        onCancel={resetPasswordModalOnClose}
                        footer={[<Button type='primary' disabled={updating} className='btn' onClick={resetPasswordBtnOnClick}>Yes</Button>]} >
                        <p>Do you want to reset user [{selectedResetPasswordUser?.user?.username}] password? </p>
                    </Modal>

                    <Modal title="Password" visible={newPassword} onCancel={newPasswordModalOnClose} footer={null} >
                        <p><b>New password:</b> {newPassword} </p> <Button onClick={(e) => navigator.clipboard.writeText(newPassword)} icon={<CopyOutlined />} />
                    </Modal>
                </Spin>
                </>
            )
        }else{
            logoutHandler();
        }
    }
    return (
        <div className='staffsPageWrapper'>
            {renderPage()}
        </div>
    );
}

export default Staffs;