const applicationsReducer = (state, action) => {
    // console.log(action);

    switch (action.type) {
        case "UPDATE_LIST":

            return {
                ...state,
                currentPage: action.payload.currentPage,
                pageSize: action.payload.pageSize,
                totalPages: action.payload.totalPages,
                totalItems: action.payload.totalItems,
                items: action.payload.items
            };

        case 'APPLICATIONS_CHANGE_PAGE_SIZE':
            return {
                ...state,
                pageSize: action.payload.pageSize
            }

        case 'APPLICATIONS_CHANGE_CURRENT_PAGE':
            return {
                ...state,
                currentPage: action.payload.currentPage
            }
        case 'CHANGE_STATUS':
            return {
                ...state,
                status: action.payload,
                currentPage: 1
            }
        case 'CHANGE_SEARCH_TEXT':
            return {
                ...state,
                text: action.payload
            }
        case 'CHANGE_SEARCH_COUNTRY':
            return {
                ...state,
                country: action.payload
            }
        default:
            console.error('NO DEFINE ACTION', action);
            return { ...state };
    }
};

export default applicationsReducer;
