import axios from "axios";
import { getApiHeaderNotTime } from "../utils/getApiHeaders";

export function getStaffs() {
    const options = {
        method: 'GET',
        url: process.env.REACT_APP_URL + `/staff/all`,
        headers: getApiHeaderNotTime(),
    }

    return axios(options)
}

export function getStaffById(id) {
    const options = {
        method: 'GET',
        url: process.env.REACT_APP_URL + `/staff/${id}`,
        headers: getApiHeaderNotTime(),
    }

    return axios(options)
}
export function getStaffCountryById(id){
    const options = {
        method: 'GET',
        url: process.env.REACT_APP_URL + `/staff/country/${id}`,
        headers: getApiHeaderNotTime(),
    }
    return axios(options)
}

/**
 * Register user
 * @param {*} form 
 * @returns 
 */
export function registerStaff(form) {
    console.log('registerStaff', form);
    const options = {
        method: 'POST',
        url: process.env.REACT_APP_URL + `/auth/register`,
        headers: getApiHeaderNotTime(),
        data: {
            username: form.username,
            email: form.email,
            firstName: form.firstName,
            lastName: form.lastName,
            password: form.password,
            passwordConfirm: form.passwordConfirm,
            group: form.group,
            country: form.country
        }
    }

    return axios(options)
}

export function activateUser(userId){
    const options = {
        method: 'PUT',
        url: process.env.REACT_APP_URL + `/staff/${userId}/activate`,
        headers: getApiHeaderNotTime(),
    }

    return axios(options)
}

export function deactivateUser(userId){
    const options = {
        method: 'PUT',
        url: process.env.REACT_APP_URL + `/staff/${userId}/deactivate`,
        headers: getApiHeaderNotTime(),
    }

    return axios(options)
}

export function updateStaff(userId, form){
    const options = {
        method: 'PUT',
        url: process.env.REACT_APP_URL + `/staff/${userId}`,
        headers: getApiHeaderNotTime(),
        data: JSON.stringify({
            username: form.username,
            email: form.email,
            firstName: form.firstName,
            lastName: form.lastName,
            isSuperuser: form.isSuperuser,
            country: form.country,
            branchId: form.branchId,
            branch: form.branch
        })
    }

    return axios(options);
}

export function updatePassword(userId, newPassword){
    const options = {
        method: 'PUT',
        url: process.env.REACT_APP_URL + `/staff/${userId}/updatepassword`,
        headers: getApiHeaderNotTime(),
        data: JSON.stringify({password: newPassword})
    }

    return axios(options)
}

export function getBranchOptions() {
    const options = {
        method: 'GET',
        url: process.env.REACT_APP_URL + '/staff/options',
        headers: getApiHeaderNotTime(),
    }
    return axios(options)
}