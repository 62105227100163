import React, { useEffect, useContext, useState } from 'react';
import { Tooltip, Spin, Row, Col, Button, Form, Modal, Select, Input } from 'antd';
import { UserOutlined, EditOutlined } from '@ant-design/icons';
import './UserDetail.css';
import Widget from '../../components/widget/Widget';
import { showErrorMessage, showSuccessMessage, showWarningMessage } from '../../components/message';
import { getUserById, updateUserInfo } from '../../apis/user';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountStatus, AccountStatusIds, UserRoles } from '../../constants';
import AccountActive from '../accountActive/AccountActive';
import { convertOptionComponent, renderSelectOptions } from '../../utils/helper';
import { AppContext } from '../../contexts/AppContext';
import { AuthContext } from '../../contexts/AuthContext';
import { AuthAction } from '../../reducers/authReducer';
import { formValidateMessages, pattern } from '../../utils/patterns';
import { getUserProfile as getUserProfileApi } from '../../apis/auth';
import { render } from '@testing-library/react';

const UserDetail = () => {

    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [userFrom, setUserFrom] = useState('');
    const [userInfoModalVisible, setUserInfoModalVisible] = useState(false);

    const [appState, appDispatch] = useContext(AppContext);    
    const [authState, authDispatch] = useContext(AuthContext);

    let [userInfoUpdateForm] = Form.useForm();
    const { options } = appState;

    let navigate = useNavigate();

    useEffect(() => {          
        getUserProfile();
    }, [])
    useEffect(() => {      
        fetchUserInfo(id);
    }, [id])
    const logoutHandler = () => {
        authDispatch({ type: 'LOGOUT' });
    }
    const getUserProfile = () => {
        getUserProfileApi()
          .then(res => {
            if (res.status === 200 && res.data.code === 10000) {
                authDispatch({ type: AuthAction.SET_USER_PROFILE, payload: res.data.payload });
                setUserFrom(res.data.payload.country.trim());
            }else{
                logoutHandler();
            }
          })
          .catch(err => {
            logoutHandler();
          })
    }
    const fetchUserInfo = (id) => {
        if (id) {
            setLoading(true);
            getUserById(id)
                .then(res => {
                    if (res.status === 200 && res.data.code === 10000) {
                        setUserInfo(res.data.payload);
                    } else {
                        showErrorMessage('Fail to get user info.');
                        console.error(res.data);
                    }
                })
                .catch(err => {
                    showErrorMessage('Issue occur when getting user info');
                    console.error(err.response);
                })
                .finally(() => {
                    setLoading(false);
                })
        } else {
            showErrorMessage('Cannot get user info');
            setUserInfo(null);
        }
    }

    const renderUserInfoWidgetContent = () => {
        if (userInfo) {
            return (
                <Row >
                    <Col span={10}>
                        <Form.Item label="Username">
                            {userInfo.username}
                        </Form.Item>
                        <Form.Item label="First Name">
                            {userInfo.firstNameCh}
                        </Form.Item>
                        <Form.Item label="Last Name">
                            {userInfo.lastNameCh}
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label="Email">
                            {userInfo.emailCh}
                        </Form.Item>
                        <Form.Item label="Role">
                            {UserRoles[userInfo.roleId]}
                        </Form.Item>
                        <Form.Item label="Status">
                            {AccountStatusIds[userInfo.status]}
                            {userInfo.status === AccountStatus.Disactive || userInfo.status === AccountStatus.Locked ? <Button className='btn'>Active</Button> : <></>}
                        </Form.Item>
                    </Col>
                </Row>
            )
        }
    }

    const renderUserAddressWidgetContent = () => {
        if (userInfo) {
            return (
                <Row >
                    <Col span={10}>
                        <Form.Item label="Address 1">
                            {userInfo.address1Ch}
                        </Form.Item>
                        <Form.Item label="City">
                            {userInfo.city}
                        </Form.Item>
                        <Form.Item label="State">
                            {userInfo.province}
                        </Form.Item>
                        <Form.Item label="Country">
                            {userInfo.country}
                        </Form.Item>
                        <Form.Item label="Zipcode">
                            {userInfo.zipCode}
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label="Address 2">
                            {userInfo.address2Ch}
                        </Form.Item>
                        <Form.Item label="Email">
                            {userInfo.emailCh}
                        </Form.Item>
                        <Form.Item label="Phone">
                            {userInfo.phone}
                        </Form.Item>
                    </Col>
                </Row>
            )
        }
    }

    const userInfoEditOnClick = () => {
        userInfoUpdateForm.setFieldsValue({
            username: userInfo.username.trim(),
            firstName: userInfo.firstNameCh.trim(),
            lastName: userInfo.lastNameCh.trim(),
            email: userInfo.emailCh.trim(),
            role: userInfo.roleId
        });
        setUserInfoModalVisible(true);
    }

    const userInfoUpdateModalOnClose = () => {
        userInfoUpdateForm.resetFields();
        setUserInfoModalVisible(false);
    }

    const userInfoOnSubmit = (f) => {

        if (!f.username || !f.firstName || !f.lastName || !f.role) {
            showWarningMessage('Input data not valid');
            return;
        }

        setUpdating(true);
        updateUserInfo(userInfo.id, f)
            .then(res => {
                if (res.status === 200 && res.data.code === 10000) {
                    showSuccessMessage();
                    fetchUserInfo(userInfo.id);
                    setUserInfoModalVisible(false);
                } else {
                    showErrorMessage('Failed to update user info. Msg:', res.data.message);
                }
            })
            .catch(err => {
                showErrorMessage('Error occur when updating user info.');

                console.log(err.response);
            })
            .finally(() => {
                setUpdating(false);
            })

        console.log('user update', f);
    }
const renderPage = () => {
    if((userInfo != null && userFrom == userInfo.country) || userFrom == "HQ"){
        return (
            <>
                    
                    <div className="userDetailTop">
                        <Row justify='space-between'>
                            <Col>
                                <div className="userDetailTitle" style={{ fontSize: '30px', fontWeight: '900' }}>{userInfo ? `${userInfo.firstNameCh} ${userInfo.lastNameCh} - ${userInfo.username}` : 'User Detail'}</div>
                            </Col>
                            <Col>
                                <Tooltip title='Check Shipper'>
                                    <Button disabled={!userInfo} onClick={() => navigate(`/dashboard/shipper/${userInfo.shipperId}`)} className='shipperBtn' icon={<UserOutlined />} style={{ borderRadius: 10 }} />
                                </Tooltip>
                            </Col>
                        </Row>
                    </div>
                    <Row className="widgetRow" gutter={[8, 8]} style={{ marginTop: '10px' }} justify='center'>
                        <Widget span={20}>
                            <div className="widgetWrapper">
                                <div className="widgetTitle">
                                    <Row justify='space-between'>
                                        <Col>
                                            Account Setting
                                        </Col>
                                        <Col>
                                            <Button className='btn widgetBtn' icon={<EditOutlined />} onClick={userInfoEditOnClick} />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="widgetContent" >
                                    {renderUserInfoWidgetContent()}
                                </div>
                            </div>
                        </Widget>
                        <Widget span={20}>
                            <div className="widgetWrapper">
                                <div className="widgetTitle">
                                    <Row justify='space-between'>
                                        <Col>
                                            <Tooltip title='User address is not relate to shipping address, if you want to modify shipping address please change shipper address'>
                                                Account Address
                                            </Tooltip>
                                        </Col>
                                        <Col>
                                            {/* <Button className='btn widgetBtn' icon={<EditOutlined />} /> */}
                                        </Col>
                                    </Row>
                                </div>
                                <div className="widgetContent" >
                                    {renderUserAddressWidgetContent()}
                                </div>
                            </div>
                        </Widget>
                    </Row>
                    <Modal title='Update User Info' visible={userInfoModalVisible} footer={[
                        <Button className='btn' type='primary' onClick={() => userInfoUpdateForm.submit()} disabled={updating}>Save</Button>
                    ]} onCancel={userInfoUpdateModalOnClose}>
                        <Form form={userInfoUpdateForm} onFinish={userInfoOnSubmit} validateMessages={formValidateMessages}>
                            <Form.Item
                                label='Username'
                                name={['username']}
                                rules={[
                                    { required: true, pattern: pattern.username, message: 'username is not valid' },
                                ]}>
                                <Input type='text' maxLength={100} />
                            </Form.Item>
                            <Form.Item label='First Name' name={['firstName']} rules={[
                                { required: true, message: 'first name is required' },
                            ]}>
                                <Input maxLength={50} />
                            </Form.Item>
                            <Form.Item label='Last Name' name={['lastName']} rules={[
                                { required: true, message: 'last name is required' },
                            ]}>
                                <Input maxLength={50} />
                            </Form.Item>
                            <Form.Item label='Email' name={['email']} rules={[
                                { required: true, type: 'email', message: 'email is not valid email' },
                            ]}>
                                <Input type='email' maxLength={100} />
                            </Form.Item>
                            <Form.Item label='Role' name={['role']} required>
                                <Select>
                                    {renderSelectOptions(options.userRole)}
                                </Select>
                            </Form.Item>
                        </Form>
                    </Modal>        
            </>
        )
    }
    else
    {
    }
}
    return (
        <div className='userDetailPageWrapper'>
            <Spin spinning={loading}>
            {renderPage()}
            </Spin>
        </div>
    );
}

export default UserDetail;