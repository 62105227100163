import axios from 'axios';
import qs from 'qs';
import { getApiHeaderNotTime } from '../utils/getApiHeaders'

export function tryLogin(data) {

    const options = {
        method: 'POST',
        url: process.env.REACT_APP_URL + '/auth/login',
        data: JSON.stringify({ username: data.username?.trim(), password: data.password?.trim() }),
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
    }

    return axios(options)
}

export function active(code) {
    const options = {
        method: 'GET',
        url: process.env.REACT_APP_URL + `/auth/active/${code}`,
        headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
    }

    return axios(options)
}

export function getUserProfile(token) {
    const options = {
        method: 'GET',
        url: process.env.REACT_APP_URL + '/staff/profile',
        headers: getApiHeaderNotTime()
    }

    return axios(options)
}