import "./App.css";
import "antd/dist/antd.css";
import { AuthContext } from "./contexts/AuthContext";
import { useContext } from "react";
import { useRoutes } from "react-router-dom";
import routes from "./components/routes/Routes";
import { ApplicationsContext, ApplicationsContextProvider } from "./contexts/ApplicationsContext";
import { AppContextProvider } from "./contexts/AppContext";
import { BackTop } from "antd";

function App() {
  const [authState, authReducer] = useContext(AuthContext);

  const { login } = authState;

  const routing = useRoutes(routes(login));

  return (
    <div className="app">
      <AppContextProvider>
        <ApplicationsContextProvider>
          {routing}
          <BackTop />
        </ApplicationsContextProvider>
      </AppContextProvider>
    </div>
  );
}

export default App;
