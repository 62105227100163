import React, { useState, useEffect, useContext } from 'react';
import { Spin, Row, Col, Button, Table, Tag, Modal, Form, Input, Select, Tooltip } from 'antd';
import { PlusOutlined, EditOutlined, UsergroupAddOutlined, DeleteOutlined } from '@ant-design/icons'
import './Groups.css';
import Widget from '../../components/widget/Widget';
import { addGroup, getGroups, removeGroup } from '../../apis/group';
import { showErrorMessage, showSuccessMessage, showWarningMessage } from '../../components/message';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { getUsers } from '../../apis/user';
import { getStaffs } from '../../apis/staff';
import CustomTable from '../../components/CustomTable/CustomTable';

const Groups = () => {

    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(false);

    const [loadingUser, setLoadingUser] = useState(false);
    const [staffs, setStaffs] = useState([]);
    const [selectedRemoveGroup, setSelectedRemoveGroup] = useState(null);
    const [removingGroup, setRemovingGroup] = useState(false);

    const [addingGroup, setAddingGroup] = useState(false);

    const [newGroupModalVisible, setNewGroupModalVisible] = useState(false);

    const [authState, authDispatch] = useContext(AuthContext);
    let navigate = useNavigate();

    const { permissions } = authState;

    useEffect(() => {
        fetchGroups();
    }, []);

    const fetchGroups = () => {
        setLoading(true);
        getGroups()
            .then(res => {
                if (res.status === 200 && res.data.code === 10000) {
                    setGroups(res.data.payload);
                } else {
                    console.debug(res.data);
                    showErrorMessage('Fail to get groups');
                }
            })
            .catch(err => {
                console.debug(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const fetchUsers = () => {
        setLoadingUser(true);
        getStaffs()
            .then(res => {
                if (res.status === 200 && res.data.code === 10000) {
                    setStaffs(res.data.payload);
                } else {
                    console.debug('fail to load users', res.data);
                }
            })
            .catch(err => {
                console.debug('having issue load user', err);
            })
            .finally(() => {
                setLoadingUser(false);
            })
    }

    const editGroupBtnOnClick = (group) => {
        if (group) {
            navigate(`/dashboard/group/${group.id}/edit`);
        }
    }

    const removeGroupBtnOnClick = (group) => {
        setSelectedRemoveGroup(group);
    }

    const tableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Permissions',
            dataIndex: 'permissions',
            key: 'permissions',
            render: (t, r) => {
                if (permissions && r.permissions) {
                    var groupPermissions = permissions.filter(p => r.permissions.includes(p.id));

                    return (
                        <>
                            {groupPermissions.slice(0, 6).map(p => <Tag key={p.codename}>{p.codename}</Tag>)}
                            {groupPermissions.length > 6 ? <Tag className='groupPermissionTag'>...</Tag> : ''}
                        </>
                    )
                }
            }
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: (t, r) => {
                return (
                    <>
                        <Tooltip title="edit">
                            <Button className='tableBtn' icon={<EditOutlined onClick={() => editGroupBtnOnClick(r)} />} />
                        </Tooltip>
                        <Tooltip title='remove'>
                            <Button className='tableBtn' icon={<DeleteOutlined onClick={() => removeGroupBtnOnClick(r)} />} />
                        </Tooltip>
                    </>
                )
            }
        }
    ]

    const addNewGroupOnClick = () => {
        fetchUsers();
        newGroupForm.setFieldsValue({ name: '', permissions: [] })
        setNewGroupModalVisible(true);
    }

    const newGroupModalOnCancel = () => {
        setNewGroupModalVisible(false);
    }

    const removeGroupModalOnCancel = () => {
        setSelectedRemoveGroup(null);
    }

    const renderUserOptions = () => {
        let options = [];

        // console.log('permissions', permissions);
        if (!staffs) {
            return options;
        }

        for (let i = 0; i < staffs.length; i++) {
            options.push(<Select.Option value={staffs[i].id} key={staffs[i].id} >{`${staffs[i].firstName} (${staffs[i].username})`}</Select.Option>)
        }

        return options;
    }

    const renderPermissionOptions = () => {
        let options = [];

        // console.log('permissions', permissions);
        if (!permissions) {
            return options;
        }

        for (let i = 0; i < permissions.length; i++) {
            options.push(<Select.Option value={permissions[i].id} key={permissions[i].id}>{permissions[i].name}</Select.Option>)
        }

        return options;
    }

    const newGroupFormOnSubmit = (v) => {
        if (!v.name) {
            showWarningMessage('Group Name Cannot be Empty');
            return;
        }

        if (v.permissions.length === 0) {
            showWarningMessage('Group Cannot Has no Permissions');
            return;
        }

        setAddingGroup(true);
        addGroup({
            name: newGroupForm.getFieldValue('name'),
            permissions: newGroupForm.getFieldValue('permissions'),
            userIds: newGroupForm.getFieldValue('staffs')
        })
            .then(res => {
                if (res.status === 200 && res.data.code === 10000) {
                    showSuccessMessage('Success');
                    setNewGroupModalVisible(false);
                    fetchGroups();
                } else {
                    showErrorMessage('Fail to create new group. Code:' + res.data.code);
                }
            })
            .catch(err => {
                console.debug('having issue create new group', err);
                showErrorMessage('Having issue create new group');
            })
            .finally(() => {
                setAddingGroup(false);
            });

    }

    const deleteBtnOnClick = (e) => {
        if (selectedRemoveGroup) {
            setRemovingGroup(true);
            removeGroup(selectedRemoveGroup.id)
                .then(res => {
                    if (res.status === 200 && res.data.code === 10000) {
                        showSuccessMessage();
                        setSelectedRemoveGroup(null);
                        fetchGroups();
                    }
                })
                .catch(err => {
                    console.error(err);
                    showErrorMessage('Fail to remove group');
                    if (err?.response?.data?.message) {
                        showErrorMessage(err?.response?.data?.message);
                    }
                })
                .finally(() => {
                    setRemovingGroup(false);
                })
        }
    }

    const [newGroupForm] = Form.useForm()

    return (
        <div className='groupsPageWrapper'>
            <Spin spinning={loading || addingGroup}>
                <div className="groupsTop">
                    <Row justify='space-between'>
                        <Col>
                            <div className="groupsTitle" style={{ fontSize: '30px', fontWeight: '900' }}>GROUPS</div>
                        </Col>
                        <Col>
                            <Tooltip title='Add New Group'>
                                <Button className='btn' type='primary' onClick={addNewGroupOnClick} icon={<UsergroupAddOutlined />} style={{ borderRadius: 10 }} />
                            </Tooltip>
                        </Col>
                    </Row>
                </div>
                <Row className="widgetRow" gutter={[8, 8]} style={{ marginTop: '10px' }} justify='center'>
                    <Widget span={20}>
                        <div className="tableWidgetWrapper">
                            <div className="tableWidgetTop">
                            </div>
                            <div className="tabelWidgetContent" >
                                {/* <Table pagination={{ position: ['topRight'], size: 'small', pageSize: 10 }} dataSource={groups} columns={tableColumns} /> */}
                                <CustomTable pageSize={5} items={groups} columns={tableColumns} />
                            </div>
                        </div>
                    </Widget>
                </Row>
                <Modal title='New Group' visible={newGroupModalVisible} onCancel={newGroupModalOnCancel} footer={[
                    <Button className='btn' type='primary' onClick={() => newGroupForm.submit()} disabled={addingGroup} >Create</Button>
                ]}>
                    <Form form={newGroupForm} onFinish={newGroupFormOnSubmit}>
                        <Form.Item name={['name']} label='Name' required={true} >
                            <Input type={'text'} />
                        </Form.Item>
                        <Form.Item name={['permissions']} label='Permissions' required={true} >
                            <Select mode='multiple' allowClear={true}>
                                {renderPermissionOptions()}
                            </Select>
                        </Form.Item>
                        <Form.Item name={['staffs']} label='User' >
                            <Select mode='multiple' allowClear={true} loading={loadingUser}>
                                {renderUserOptions()}
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal visible={selectedRemoveGroup} title='Remove Group' onCancel={removeGroupModalOnCancel} footer={[
                    <Button disabled={removingGroup} className='btn' type='primary' onClick={deleteBtnOnClick}>Yes</Button>
                ]}>
                    <p>Do you want to remove [{selectedRemoveGroup?.name}] group?</p>
                </Modal>
            </Spin>
        </div>
    );
}

export default Groups;