import React, { useContext, useEffect } from "react";
import { Layout } from "antd";
import { Routes, Route, Outlet, useNavigate, useLocation } from "react-router";
import Login from "../login/Login";
import { AuthContext } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import logo from '../../resource/img/yship-logo.png';

import './EmptyLayout.css';

const { Header, Content, Footer } = Layout;

const EmptyLayout = ({ element, children }) => {

  const [authState, authReducer] = useContext(AuthContext);

  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/register') {
      var token = localStorage.getItem('access_token') || authState.token;
      if (token) {

        console.log('path', location.pathname);

        authReducer({ type: 'LOGIN', payload: token });

        navigate(location.pathname);
      }
    }
  }, [])

  return (
    <Layout className="emptyLayoutWrapper">
      <Layout.Header>
        <div className='logo'>
          <img className="yshipLogo" src={logo} />
        </div>
      </Layout.Header>
      <Content
        className="emptyPage"
        style={{ alignContent: "center" }}
      >
        <Outlet />
      </Content>
      <Layout.Footer>
        <div className="layoutFooter">
          
        </div>
      </Layout.Footer>
    </Layout>
  );
};

export default EmptyLayout;
