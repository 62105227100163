import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom';
import { Spin, Row, Col, Button, Skeleton, Form, Input, Table, Modal, Select } from 'antd';
import { PlusOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';

import './GroupEdit.css';
import { addUserToGroup, getGroupById, removeUserFromGroup, updateGroup } from '../../apis/group';
import Widget from '../../components/widget/Widget';
import { AuthContext } from '../../contexts/AuthContext';
import { groupBy } from '../../utils/helper';
import { showErrorMessage, showSuccessMessage, showWarningMessage } from '../../components/message';
import { getStaffs } from '../../apis/staff';

const GroupEdit = () => {

    const { groupId } = useParams();

    const [group, setGroup] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingUserOptons, setLoadingUserOptions] = useState(false);
    const [saving, setSaving] = useState(false);
    const [addUserModalVisible, setAddUserModalVisible] = useState(false);
    const [removeUserModalVisible, setRemoveUserModalVisible] = useState(false);
    const [addUserOptions, setAddUserOptions] = useState([]);
    const [selectedAddUser, setSelectedAddUser] = useState(null);
    const [selectedRemoveUser, setSelectedRemoveUser] = useState(null);
    const [addUserProcessing, setAddUserProcessing] = useState(false);
    const [removeUserProcessing, setRemoveUserProcessing] = useState(false);
    const [authState, authDispatch] = useContext(AuthContext);

    const [form, setForm] = useState({ name: '', permissions: [] })

    const { permissions: systemPermissions } = authState;

    useEffect(() => {
        if (groupId) {
            fetchGroup(groupId)
        }
    }, [])

    useEffect(() => {
        setForm({ ...group })
    }, [group])

    const fetchGroup = (groupId) => {
        setLoading(true);
        if (groupId) {
            getGroupById(groupId)
                .then(res => {
                    if (res.status === 200 && res.data.code === 10000) {
                        setGroup(res.data.payload);
                    }
                })
                .catch(err => {
                    console.debug(err);
                })
                .finally(() => {
                    setLoading(false);
                })
        } else {
            throw 'No group id';
        }
    }

    const renderTitle = () => {
        if (loading) {
            return <Skeleton.Input />
        } else {
            return (group ? 'GROUP EDIT' : 'GROUP NOT FOUND');
        }
    }

    const userColumns = [
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'firstName',
            render: (t, r) => `${r.firstName} ${r.lastName}`
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username'
        },
        {
            title: 'Actions',
            key: 'id',
            render: (t, r) => {
                return (
                    <div>
                        <Button className='tableBtn' icon={<EyeOutlined />} />
                        <Button className='tableBtn tableDeleteBtn' onClick={() => removeBtnOnClick(r)} icon={<DeleteOutlined />} />
                    </div>
                );
            }
        }
    ]

    const groupFormValidateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
        },
    };

    const groupFormOnFinish = (group) => {
        console.log('save group', group);
    }

    const renderPermissionCheckBoxs = () => {

        if (systemPermissions && form.permissions) {
            let groupPermissions = groupBy(systemPermissions, p => p.contentTypeId);
            let permissionCheckBoxs = [];
            groupPermissions.forEach(k => {
                permissionCheckBoxs.push(
                    <Widget span={6} className="permissionGroup">
                        {k.map(p => <>{p.name}: <Input key={p.id} defaultChecked={form.permissions.includes(p.id)} onChange={groupPermissionOnCheck} checked={form.permissions.includes(p.id)} type={'checkbox'} value={p.id} /><br /></>)}
                    </Widget>
                )
            });

            return permissionCheckBoxs;
        }
    }

    const groupNameOnChange = (v) => {
        setForm({
            ...form,
            name: v.target.value
        })
    }

    const groupPermissionOnCheck = (v) => {
        var clickedId = parseInt(v.target.value);
        if (form.permissions.includes(clickedId)) {
            let newPermissions = form.permissions.filter(p => p !== clickedId);
            console.log('new permissions', newPermissions);
            setForm({
                ...form,
                permissions: newPermissions
            })
        } else {
            let newPermissions = [...form.permissions];
            newPermissions.push(clickedId);
            setForm({ ...form, permissions: newPermissions })
        }
    }

    const saveBtnOnClick = () => {
        if (!form.name) {
            showWarningMessage('Group name cannot be empty');
            return;
        }

        if (!form.permissions.length === 0) {
            showWarningMessage('Group permission must has permission')
            return;
        }

        setSaving(true);
        updateGroup(group.id, form)
            .then(res => {
                if (res.status === 200 && res.data.code === 10000) {
                    showSuccessMessage('Saved')
                    fetchGroup(group.id);
                } else {
                    showErrorMessage('Fail to save group. Code: ' + res.data.code);
                }
            })
            .catch(err => {
                showErrorMessage('Having issue to save group');
                console.debug(err);
            })
            .finally(() => {
                setSaving(false);
            })
    }

    const getAddUserOptions = () => {
        setLoadingUserOptions(true);
        getStaffs()
            .then(res => {
                if (res.status === 200 && res.data.code === 10000) {
                    let groupUserIds = group.users.map(gu => gu.id);
                    let options = res.data.payload.filter(u => !groupUserIds.includes(u.id));
                    console.log('options', options);
                    setAddUserOptions(options.map(u => <Select.Option key={u.id}>{`${u.firstName} ${u.lastName} (${u.username})`}</Select.Option>))
                } else {
                    showErrorMessage('Fail to get user options')
                }
            })
            .catch(err => {
                console.debug(err);
                showErrorMessage('Having issue to get user options')
            })
            .finally(() => {
                setLoadingUserOptions(false);
            })
    }

    const addUserBtnOnClick = (e) => {
        setAddUserModalVisible(!addUserModalVisible);
        getAddUserOptions();
    }

    const addUserOnChange = (v, o) => {
        console.log('select user', v, o);
        setSelectedAddUser(v);
    }

    const addUserModalOnCancel = () => {
        setAddUserModalVisible(false)
        setSelectedAddUser(null);
    }

    const removeBtnOnClick = (user) => {
        setSelectedRemoveUser(user);
        setRemoveUserModalVisible(true);
    }

    const removeUserModalOnCancel = () => {
        setSelectedRemoveUser(null);
        setRemoveUserModalVisible(false);
    }

    const addUserModalOnOk = () => {
        if (selectedAddUser) {
            setAddUserProcessing(true);
            addUserToGroup(group.id, selectedAddUser)
                .then(res => {
                    if (res.status === 200 && res.data.code === 10000) {
                        showSuccessMessage('Success');
                        addUserModalOnCancel();
                        fetchGroup(group.id);
                    } else {
                        showErrorMessage('Fail to add user. Code:' + res.data.code);
                    }
                })
                .catch(err => {
                    console.debug(err);
                })
                .finally(() => {
                    setAddUserProcessing(false);
                })
        } else {
            showWarningMessage('Please select user');
        }
    }

    const removeUserModalOnOk = () => {
        if (!selectedRemoveUser) {
            showWarningMessage('Please select user to remove');
            return;
        }
        setRemoveUserProcessing(true);
        removeUserFromGroup(group.id, selectedRemoveUser.id)
            .then(res => {
                if (res.status === 200 && res.data.code === 10000) {
                    showSuccessMessage('Success');
                    removeUserModalOnCancel();
                    fetchGroup(group.id);
                } else {
                    showErrorMessage('Fail to remove user from group. Code: ' + res.data.code);
                }
            })
            .catch(err => {
                showErrorMessage('Having issue to remove user from group');
            })
            .finally(() => {
                setRemoveUserProcessing(false);
            })
    }

    return (
        <div className='groupEditPageWrapper'>
            <Spin spinning={loading}>
                <div className="groupEditTop">
                    <Row justify='space-between'>
                        <Col>
                            <div className="groupEditTitle" style={{ fontSize: '30px', fontWeight: '900' }}>
                                {renderTitle()}
                            </div>
                        </Col>
                        <Col>
                            <Button className='btn' type='primary' htmlType='button' onClick={saveBtnOnClick} disabled={saving}>
                                {saving ? <Spin spinning /> : 'Save'}
                            </Button>
                        </Col>
                    </Row>
                </div>
                <Row className="widgetRow" gutter={[8, 8]} style={{ marginTop: '10px' }}>
                    <Widget loading={loading} span={24}>
                        <div className="widgetWrapper">
                            <div className="widgetTop">
                                <Row>
                                    <Col>
                                        Form
                                    </Col>
                                </Row>
                            </div>
                            <div className="widgetContent" >
                                <Form labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="groupEdit" onFinish={groupFormOnFinish} validateMessages={groupFormValidateMessages} >
                                    <Form.Item name={['name']} label="Name" rules={[{ required: true }]} wrapperCol={{ span: 8 }}>
                                        <Input defaultValue={form.name} autoComplete='off' onChange={groupNameOnChange} />
                                    </Form.Item>
                                    <Form.Item name={['permissions']} label="Permissions" >
                                        <Row gutter={[8, 8]}>
                                            {renderPermissionCheckBoxs()}
                                        </Row>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </Widget>
                </Row>
                <Row className='widgetRow' gutter={[8, 8]}>
                    <Widget loading={loading} span={12}>
                        <div className='widgetWrapper'>
                            <div className="widgetTop">
                                <Row justify='space-between'>
                                    <Col>
                                        Users In Group
                                    </Col>
                                    <Col>
                                        <Button className='btn' type='primary' icon={<PlusOutlined />} onClick={addUserBtnOnClick} />
                                    </Col>
                                </Row>
                            </div>
                            <div className="widgetContent" >
                                <Table dataSource={group ? group.users : []} columns={userColumns} />
                            </div>
                        </div>
                    </Widget>
                </Row>
            </Spin>
            <Modal title='Add User To Group' visible={addUserModalVisible} onCancel={addUserModalOnCancel} onOk={addUserModalOnOk} okButtonProps={{ disabled: addUserProcessing }}>
                <Select
                    allowClear
                    loading={loadingUserOptons}
                    style={{ width: '100%' }}
                    onChange={addUserOnChange}
                    value={selectedAddUser}
                    placeholder="Please select">
                    {addUserOptions}
                </Select>
            </Modal>
            <Modal
                title='Remove User From Group'
                visible={removeUserModalVisible}
                onCancel={removeUserModalOnCancel}
                onOk={removeUserModalOnOk}
                footer={[
                    <Button
                        onClick={removeUserModalOnOk}
                        disabled={removeUserProcessing}
                        icon={<DeleteOutlined />}
                        className='removeBtn' />
                ]}>
                Do you want to remove user <strong>{selectedRemoveUser ? selectedRemoveUser.username : ''}</strong> from group <strong>{group ? group.name : ''}</strong>?
            </Modal>
        </div>
    )
}

export default GroupEdit;