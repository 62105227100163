// import history from './history'

export default class Storage {
    static get = (key) => {
        if (!localStorage.getItem(key) || typeof localStorage.getItem(key) !== 'string') return;
        return JSON.parse(localStorage.getItem(key))
    }

    static set = (key, value) => {
        localStorage.setItem(key, JSON.stringify(value))
    }

    static remove = (key) => {
        localStorage.removeItem(key)
    }

    static clear = () => {
        localStorage.clear()
    }

    static clearAll = () => {
        localStorage.clear();
        // history.push('/login')
    }

    static accessToken = () => {
        if (!localStorage.getItem('access_token') || !Storage.get('currencies') || !Storage.get('countries') || !Storage.get('user')) {
            // history.push('/login')
            Storage.clearAll()
            return false;
        }
    }
}

