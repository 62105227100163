import React, { useEffect, useContext, useState } from "react";
import { Layout, Menu, Row, Col, Avatar, Popover, Modal, Form, Input, Button, Tooltip } from "antd";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  UserOutlined,
  LaptopOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { AuthContext } from "../../contexts/AuthContext";
import { getUserProfile as getUserProfileApi } from '../../apis/auth';

import './mainLayout.css';
import { getAllPermissions, getPermissions } from "../../apis/permission";
import { AuthAction } from "../../reducers/authReducer";
import { getOptions } from "../../apis/common";
import { AppContext } from "../../contexts/AppContext";
import { appAction } from "../../reducers/appReducer";
import { showErrorMessage, showSuccessMessage, showWarningMessage } from "../../components/message";
import { updatePassword } from "../../apis/staff";
import { pattern } from '../../utils/patterns';
import { scrollToPos } from "../../utils/helper";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const MainLayout = (props) => {
  const [authState, authDispatch] = useContext(AuthContext);
  const [appState, appDispatch] = useContext(AppContext);

  let navigate = useNavigate();
  let location = useLocation();

  const { token, user, } = authState;
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const [passwordInput, setPasswordInput] = useState('');
  const [updatingPassword, setUpdatingPassword] = useState(false);

  const [updatePasswordForm] = Form.useForm();

  useEffect(() => {
    var token = localStorage.getItem('access_token') || authState.token;
    if (token) {

      authDispatch({ type: 'LOGIN', payload: token });

      navigate(location.pathname);
    }

    fetchPermissions();
    fetchOptions();
    getUserProfile()
  }, [])

  const logoutHandler = () => {
    authDispatch({ type: 'LOGOUT' });
  }

  const getUserProfile = () => {
    getUserProfileApi()
      .then(res => {
        if (res.status === 200 && res.data.code === 10000) {
          authDispatch({ type: AuthAction.SET_USER_PROFILE, payload: res.data.payload });
        } else {
          logoutHandler();
        }
      })
      .catch(err => {
        logoutHandler();
      })
  }

  const fetchPermissions = () => {
    getAllPermissions()
      .then(res => {
        if (res.status === 200 && res.data.code === 10000) {
          authDispatch({ type: AuthAction.UPDATE_PERMISSIONS, payload: res.data.payload });
        }
      })
      .catch(err => {
        console.debug(err);
      })
  }

  /**
   * Get edit options and set it to app context
   * @returns 
   */
  const fetchOptions = () => {
    getOptions()
      .then(res => {
        if (res.status === 200 && res.data.code === 10000) {
          appDispatch({ type: appAction.SET_OPTIONS, payload: res.data.payload });
        } else {
          console.debug('Fail to get options', res);
        }
      })
      .catch(err => {
        console.debug('Having issue to get options', err);
      })
  }

  const profilePopover = (
    <div className="avatarPopover">
      <Col>
        <div
          className="avatarPopoverItem"
          onClick={(e) => setProfileModalVisible(true)}
          style={{
            borderRadius: "5px",
            marginTop: "5px",
            borderBottom: '1px',
          }}
        >
          Profile
        </div>
        <div
          className="avatarPopoverItem"
          style={{
            borderRadius: "5px",
            marginTop: "5px",
          }}
          onClick={logoutHandler}
        >
          Logout
        </div>
        <div
          className="avatarPopoverItem"
          style={{
            borderRadius: "5px",
            marginTop: "5px",
          }}
          onClick={() => window.open(process.env.REACT_APP_YSC)}
        >
          Y-Ship Cloud
        </div>
        <div
          className="avatarPopoverItem"
          style={{
            borderRadius: "5px",
            marginTop: "5px",
          }}
        >
          Version: {process.env.REACT_APP_VERSION}
        </div>
      </Col>
    </div>
  );

  const profileModalOnClose = () => {
    setProfileModalVisible(false);
    setPasswordInput('');
  }

  const updatePasswordBtnOnClick = (f) => {
    if (!f.password) {
      showWarningMessage('Please input password');
      return;
    }

    setUpdatingPassword(true);
    updatePassword(f.id, f.password)
      .then(res => {
        if (res.status === 200 && res.data.code === 10000) {
          showSuccessMessage('Success');
        } else {
          console.warn('Fail to update password', res.data);
          showErrorMessage('Fail to update password');
        }
      })
      .catch(err => {
        console.error(err);
        showErrorMessage('Have issue updating password');
      })
      .finally(() => {
        setUpdatingPassword(false);
      })
  }

  const renderProfileContent = () => {
    if (user) {
      return (
        <>
          <Form
            onFinish={updatePasswordBtnOnClick}
            initialValues={{
              id: user.id,
              username: user.username,
              name: `${user.firstName} ${user.lastName}`,
              password: ''
            }}>
            <Form.Item label='id' name={['id']} hidden />
            <Form.Item label='Username' name={['username']}>
              {user.username}
            </Form.Item>
            <Form.Item label='Name' name={['name']}>
              {`${user.firstName} ${user.lastName}`}
            </Form.Item>
            <Form.Item
              label={<Tooltip title='your password must at least 8 characters and contain: 1 lower case charater, 1 upper case character, 1 special character: !@#$%^&*'>Update Password?</Tooltip>}
              name={['password']}
              rules={[
                { pattern: pattern.password, message: 'Password is not valid' }
              ]}>
              <Input type={'password'} minLength={8} />
            </Form.Item>

            <Button disabled={updatePassword} htmlType='submit' type="primary" className="btn">Update</Button>
            {/* <Button disabled={updatingPassword} onClick={updatePasswordBtnOnClick} className="btn updatePasswordPassword" >Update</Button> */}
          </Form>
        </>
      )
    }
  }
  const renderSettings = () => {
    if(user){
      if(user.country.trim() == 'HQ'){
        return(
          <>
            <SubMenu key="sub2" className="menuItem" title="Settings">
              <Menu.Item className="menuItem" key="sub2-1"><Link className="pageLink" to="/dashboard/staffs">Staff</Link></Menu.Item>
              <Menu.Item className="menuItem" key="sub2-2"><Link className="pageLink" to="/dashboard/groups">Group</Link></Menu.Item>
            </SubMenu>
          </>
        )
      }
    }
  }
  return (
    <Layout>
      <Header className="header disableSelect" style={{ position: 'fixed', zIndex: 100, width: '100%' }}>
        <Row justify="space-between">
          <Col>
            <Menu mode="horizontal" defaultSelectedKeys={[1]}>
              <Menu.Item onClick={() => navigate('/dashboard')} key={1} style={{ fontSize: '25px', fontWeight: 900, width: 300, borderBottom: null }} >
                Y-Ship Cloud Dashboard
              </Menu.Item>
            </Menu>
          </Col>
          <Col>
            <Popover
              placement="bottom"
              title={`Hi, ${user ? user.firstName : ''}`}
              content={profilePopover}
            >
              <Avatar icon={<UserOutlined />} />
            </Popover>
          </Col>
        </Row>
      </Header>
      <Layout style={{ padding: '', marginTop: 64 }}>
        <Sider width={200} className="sidebarWrapper disableSelect" style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}>
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{ height: "100%", borderRight: 0 }}
          >
            <Menu.Item key="1" className="menuItem"><Link className="pageLink" to="/dashboard/applications"> Applications</Link></Menu.Item>
            <SubMenu key="sub1" className="menuItem" title="Shippers">
              <Menu.Item className="menuItem" key="sub1-1"><Link className="pageLink" to="/dashboard/users">Users</Link></Menu.Item>
              <Menu.Item className="menuItem" key="sub1-2"><Link className="pageLink" to="/dashboard/shippers">Shippers</Link></Menu.Item>
            </SubMenu>
          {renderSettings()}
          </Menu>
        </Sider>
        <Layout style={{ padding: "0 24px 24px" }}>
          <Content
            className="contentWrapper"
            style={{
              padding: 24,
              marginLeft: 200,
              minHeight: '100vh',
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
      <Modal className="profileModal" visible={profileModalVisible} onCancel={profileModalOnClose} footer={null} title='Profile'>
        {renderProfileContent()}
      </Modal>
    </Layout>
  );
};

export default MainLayout;
