import { Select } from 'antd';
import propTypes from 'prop-types';
import React from 'react';
import { DestinationCountries } from '../../constants';

const DestinationCountrySelect = ({value, addition, disabled, onChange}) => {

    const options = [];

    for (var country in DestinationCountries) {
        options.push({
            id: country,
            country: DestinationCountries[country]
        })
    }
   let countries = [];
   value = String(value);
   if(value.length > 0)
   {
        countries = value.split(",");
        countries.sort();
   }
    return (
        <Select maxLength={20} value={countries} mode="multiple" disabled={disabled} onChange={onChange}>
            {options.map((destinationCountries) => {
                return <Select.Option value={destinationCountries.id} >{destinationCountries.country}</Select.Option>
            })}
        </Select>
    )
}

DestinationCountrySelect.defaultProps = {
    value: '',
    addition: [],
    disabled: false,
}

export default DestinationCountrySelect;