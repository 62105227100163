import axios from "axios";
import { getApiHeaderNotTime } from "../utils/getApiHeaders";

export function getAllPermissions() {
    const options = {
        method: 'GET',
        url: process.env.REACT_APP_URL + `/permission/all`,
        headers: getApiHeaderNotTime(),
    }
    return axios(options)
}

// Pass user_id
export function getPermissions(id) {
    const options = {
        method: 'GET',
        url: process.env.REACT_APP_URL + `/permission/${id}`,
        headers: getApiHeaderNotTime(),
    }
    return axios(options)
}

