
export const pattern = {
    email: /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9-]+\.)+[A-Za-z]+$/,
    password: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,32}/,
    alphnum: /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s]*$/,
    phonenum: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
};

export function matchPattern(input, pattern){
    if(!input || !pattern || typeof(pattern) !== RegExp) return false;

    return pattern.test(input);
}

export const formValidateMessages = {
    required: 'Please input ${label}',
    types: {
        email: 'Please input valid email address for ${label}',
        number: 'Please input valid number for ${label}',
        password: 'Pleaes input valid password',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
    pattern: '${label} is not valid'
};