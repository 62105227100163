import axios from "axios";
import { getApiHeaderNotTime, getApiHeaderNotTimeForm } from "../utils/getApiHeaders";
import { getValue, checkPaymentTerms } from "../utils/helper";

/**
 * Submit application
 * @param {*} form 
 * @returns 
 */
export function submitApplication(form){
    const options = {
        method: 'POST',
        url: process.env.REACT_APP_URL + `/application/submit`,
        headers: getApiHeaderNotTimeForm(),
        data: form
    }
    return axios(options)
}

export function startReviewApplication(applicationId){
    const options = {
        method: 'PUT',
        url: process.env.REACT_APP_URL + `/application/${applicationId}/startreview`,
        headers: getApiHeaderNotTimeForm(),
    }
    return axios(options)
}

export function holdApplication(applicationId){
    const options = {
        method: 'PUT',
        url: process.env.REACT_APP_URL + `/application/${applicationId}/onhold`,
        headers: getApiHeaderNotTimeForm(),
    }
    return axios(options)
}

export function searchApplicatoin(query) {
    const options = {
        method: 'GET',
        url: process.env.REACT_APP_URL + `/application/search`,
        headers: getApiHeaderNotTime(),
        params: {
            pageNumber: query.pageNumber,
            pageSize: query.pageSize,
            status: query.status,
            text: getValue(query.text).trim(),
            country: getValue(query.country).trim()
        }
    }

    return axios(options)
}

export function updateApplication(applicationId, form) {
    if(form.paymentCh == null)
    {
        form.paymentCh = "";
    }
    if(typeof form.additionalPaymentsCh == "string")
    {
        form.additionalPaymentsCh = form.additionalPaymentsCh.trim().split(",");
    }
    let formPaymentCh = form.paymentCh.toString().trim();
    if(!form.additionalPaymentsCh.includes(formPaymentCh))
    {
        form.additionalPaymentsCh.push(formPaymentCh);
    }
    let splittedTerms = form.additionalPaymentsCh.filter(payment => checkPaymentTerms(payment)).map(payment => {
        var paymentToReturn = payment;
        return paymentToReturn;
    }).sort();
    splittedTerms = [...new Set(splittedTerms)];
    form.additionalPaymentsCh = splittedTerms.join(",");
    // Destination
    if(Array.isArray(form.destinationCountryCh)){
        form.destinationCountryCh = form.destinationCountryCh.join(',');
    }
    const options = {
        method: 'PUT',
        url: process.env.REACT_APP_URL + `/application/${applicationId}/update`,
        headers: getApiHeaderNotTime(),
        data: JSON.stringify({...form })
    }

    return axios(options)
}

export function completeApplication(applicationId) {
    const options = {
        method: 'PUT',
        url: process.env.REACT_APP_URL + `/application/${applicationId}/complete`,
        headers: getApiHeaderNotTime()
    }

    return axios(options)
}

export function getApplicationById(id) {
    const options = {
        method: 'GET',
        url: process.env.REACT_APP_URL + `/application/${id}`,
        headers: getApiHeaderNotTime(),
    }

    return axios(options)
}

export function rejectApplication(applicationId) {
    const options = {
        method: 'PUT',
        url: process.env.REACT_APP_URL + `/application/${applicationId}/reject`,
        headers: getApiHeaderNotTime()
    }

    return axios(options)
}

export function getApplicationFileById(id) {
    const options = {
        method: 'GET',
        url: process.env.REACT_APP_URL + `/application/${id}/file`,
        responseType: 'blob', // Important
        headers: getApiHeaderNotTime(),
    }
    return axios(options)
}

export function uploadApplicationFile(id, form) {
    const options = {
        method: 'PUT',
        url: process.env.REACT_APP_URL + `/application/${id}/file`,
        headers: getApiHeaderNotTimeForm(),
        data: form
    }
    return axios(options)
}

export function getStatus() {
    const options = {
        method: 'GET',
        url: process.env.REACT_APP_URL + `/application/status`,
        headers: getApiHeaderNotTime(),
    }

    return axios(options)
}

export function getStatusByCountry(country) {
    const options = {
        method: 'GET',
        url: process.env.REACT_APP_URL + `/application/status/${country}`,
        headers: getApiHeaderNotTimeForm()
    }
    return axios(options)
}