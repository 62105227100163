import axios from "axios";
import { getApiHeaderNotTime } from "../utils/getApiHeaders";
import { getValue, checkPaymentTerms } from "../utils/helper";

// const searchCondition = {
//     pageNumber: 0,
//     pageSize: 10,
//     name: '',
//     shipperCode: '',
//     branch: '',
//     ServiceType: ''
// }

/**
 * 
 * @param {*} searchCondition 
 * @returns 
 */
export function searchShipperss(searchCondition) {
    const options = {
        method: 'GET',
        url: process.env.REACT_APP_URL + '/shipper/search',
        headers: getApiHeaderNotTime(),
        params: {
            name: getValue(searchCondition.name).trim(),
            shipperCode: getValue(searchCondition.shipperCode).trim(),
            branch: searchCondition.branch,
            pageNumber: searchCondition.pageNumber || 1,
            pageSize: searchCondition.pageSize || 10,
            country: searchCondition.country
        }
    }

    return axios(options)
}

export function getShipperById(shipperId) {
    const options = {
        method: 'GET',
        url: process.env.REACT_APP_URL + `/shipper/${shipperId}`,
        headers: getApiHeaderNotTime(),
    }

    return axios(options)
}

/**
 * Update shipper basic info
 * @param {*} shipperId 
 * @param {*} form 
 * @returns 
 */
export function updateShipperInfo(shipperId, form) {
    if(typeof form.paymentTerms == "string")
    {
        form.paymentTerms = form.paymentTerms.trim().split(",");
    }
    let defaultPaymentTerm = form.defaultPaymentTerm.toString().trim()
    if(!form.paymentTerms.includes(defaultPaymentTerm))
    {
        form.paymentTerms.push(defaultPaymentTerm);
    }
    let splittedTerms = form.paymentTerms.filter(payment => checkPaymentTerms(payment)).map(payment => {
        var paymentToReturn = payment;
        return paymentToReturn;
    }).sort();
    splittedTerms = [...new Set(splittedTerms)];
    form.paymentTerms = splittedTerms.join(",");
    form.destinationCountry = Array.isArray(form.destinationCountry) ? form.destinationCountry.join(',') : form.destinationCountry;
    const options = {
        method: 'PUT',
        url: process.env.REACT_APP_URL + `/shipper/${shipperId}/updateInfo`,
        headers: getApiHeaderNotTime(),
        data: JSON.stringify({
            shipperCode: form.shipperCode,
            shipperName: form.shipperName,
            defaultPaymentTerm: form.defaultPaymentTerm,
            paymentTerms: form.paymentTerms,
            destinationCountry: form.destinationCountry
        })
    }

    return axios(options);
}

/**
 * Update shipper basic info
 * @param {*} shipperId 
 * @param {*} form 
 * @returns 
 */
 export function updateShipperAddress(shipperId, form) {
    const options = {
        method: 'PUT',
        url: process.env.REACT_APP_URL + `/shipper/${shipperId}/updateaddress`,
        headers: getApiHeaderNotTime(),
        data: JSON.stringify({
            attention: form.attention,
            address: form.address,
            address2: form.address2,
            city: form.city,
            state: form.state,
            country: form.country,
            zipcode: form.zipcode,
            phone: form.phone,
            mobile: form.mobile,
            email: form.email,
        })
    }

    return axios(options);
}

export function addShipperChannel(shipperId, form) {
    const options = {
        method: 'PUT',
        url: process.env.REACT_APP_URL + `/shipper/${shipperId}/addchannel`,
        headers: getApiHeaderNotTime(),
        data: JSON.stringify({
            channel: form.channel,
            shipperCode: form.shipperCode,
        })
    }

    return axios(options);
}

export function updateShipperChannel(shipperId, form) {
    const options = {
        method: 'PUT',
        url: process.env.REACT_APP_URL + `/shipper/${shipperId}/updatechannel`,
        headers: getApiHeaderNotTime(),
        data: JSON.stringify({
            channel: form.channel,
            shipperCode: form.shipperCode,
        })
    }

    return axios(options);
}

export function removeShipperChannel(shipperId, channel) {
    const options = {
        method: 'DELETE',
        url: process.env.REACT_APP_URL + `/shipper/${shipperId}/removechannel`,
        headers: getApiHeaderNotTime(),
        data: JSON.stringify({
            channel: channel
        })
    }

    return axios(options);
}