import { Select } from "antd";
import { PaymentTerms } from "../constants";

/**
 * scroll dom to position
 *
 * @param float top
 * @param float left
 */
export const scrollToPos = (dom, top = 0, left = 0) => {
    try {
        dom.scrollTop = top;
        dom.scrollLeft = left;
        dom.scrollTo(top, left);
    } catch (err) { }
}

/**
 * parse url from a string => object full information
 */
export const parseUrlFromString = (url) => {
    if (!url) {
        return null;
    }
    var match = url.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
    if (!match) {
        return null;
    }
    var result = {
        href: url,
        protocol: match[1],
        host: match[2],
        hostname: match[3],
        port: match[4],
        pathname: match[5],
        search: match[6],
        hash: match[7],
        domain: null,
    };
    if (!result.hostname) {
        return result;
    }
    var matchSubdomain = result.hostname.match(/\./g);
    if (!matchSubdomain || matchSubdomain.length < 2) {
        return result;
    }
    if (!isNaN(result.hostname.substr(0, result.hostname.indexOf('.')))) {
        //result.domain = result.hostname;
        return result;
    }
    result.domain = result.hostname.substr(result.hostname.indexOf('.') + 1);
    return result;
}

/**
 * Validate string is valid email format
 * @param {*} email 
 * @returns 
 */
export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        if (!map.has(key)) {
            map.set(key, [item]);
        } else {
            map.get(key).push(item);
        }
    });
    return map;
}

export const renderSelectOptions = (options) => {
    return convertOptionComponent(options);
}

export function convertOptionComponent(list) {
    if (!list || list.length === 0) {
        return;
    } else {
        var ops = list.map(i => <Select.Option key={i.value} value={i.value} >{i.name}</Select.Option>)
        ops.unshift(<Select.Option value={null} >{''}</Select.Option>)
        return ops;
    }
}

export function getFileExt(filename) {
    return filename.split('.').pop();
}

export function getValue(value) {
    return value || '';
}

export function getRandonString(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export function getRandomPassword(){
    var result = '';
    var lowerChar = 'abcdefghijklmnopqrstuvwxyz';
    var upperChar = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var number = '0123456789';
    var specialChar = '!@#$%^&*'
    var chars = [lowerChar, upperChar, number, specialChar];

    result += lowerChar.charAt(Math.floor(Math.random() * lowerChar.length));
    for(var i=0; i< 10; i++){
        var charList = chars[i % chars.length];
        result += charList.charAt(Math.floor(Math.random() * charList.length));
    }
    result += lowerChar.charAt(Math.floor(Math.random() * lowerChar.length));

    return result;
}

export function checkPaymentTerms(value){
    value = String(value);
    return Object.keys(PaymentTerms).includes(value);
}