import { Navigate, Outlet } from 'react-router-dom';
import AccountActive from '../../pages/accountActive/AccountActive';
import ApplicationEdit from '../../pages/applicationEdit/ApplicationEdit';
import Applications from '../../pages/applications/Applications';
import ApplicationView from '../../pages/applicationView/ApplicationView';
import EmptyLayout from '../../pages/emptyLayout/EmptyLayout';
import GroupEdit from '../../pages/groupEdit/GroupEdit';
import Groups from '../../pages/groups/Groups';
import Login from '../../pages/login/Login';
import MainLayout from '../../pages/mainLayout/MainLayout';
import NotFound from '../../pages/notFound/NotFound';
import ShipperDetail from '../../pages/shipperDetail/ShipperDetail';
import Shippers from '../../pages/shippers/Shippers';
import Staffs from '../../pages/staffs/Staffs';
import UserDetail from '../../pages/userDetail/UserDetail';
import UserRegister from '../../pages/userRegister/UserRegister';
import Users from '../../pages/users/Users';

const routes = (isLoggedIn) => [
  {
    path: '*',
    element: <EmptyLayout />,
    children: [
      { path: '*', element: <NotFound /> }
    ]
  },
  {
    path: '/',
    element: <EmptyLayout />,
    children: [
      { path: '/', element: <Navigate to={'/register'} /> },
      { path: '/register', element: <UserRegister /> }
    ]
  },
  {
    path: '/auth',
    element: isLoggedIn ? <Navigate to='/dashboard' /> : <EmptyLayout />,
    children: [
      { path: '/auth/active/:code', element: <AccountActive /> },
      { path: '/auth/login', element: <Login /> },
      { path: '', element: <Navigate to="/auth/login" /> },
    ],
  },
  {
    path: '/dashboard',
    element: isLoggedIn ? <MainLayout /> : <Navigate to="/auth/login" />,
    children: [
      { path: '/dashboard/applications', element: <Applications /> },
      { path: '/dashboard/application/:id/edit', element: <ApplicationEdit /> },
      { path: '/dashboard/application/:id/view', element: <ApplicationView /> },
      { path: '/dashboard/users', element: <Users /> },
      { path: '/dashboard/user/:id', element: <UserDetail /> },
      { path: '/dashboard/shippers', element: <Shippers /> },
      { path: '/dashboard/shipper/:id', element: <ShipperDetail /> },
      { path: '/dashboard/staffs', element: <Staffs /> },
      { path: '/dashboard/groups', element: <Groups /> },
      { path: '/dashboard/group/:groupId/edit', element: <GroupEdit /> },
      { path: '/dashboard', element: <Navigate to="/dashboard/applications" /> },
      { path: '*', element: <NotFound /> }
    ],
  },
];

export default routes;