import { Button, Row } from 'antd';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Widget from '../../components/widget/Widget';
import './NotFound.css';

const NotFound = () => {

    let navigate = useNavigate();
    let location = useLocation();

    const renderBtn = () => {
        const path = location.pathname;

        if (!path.startsWith('/dashboard')) {
            return (
                <Row justify='center'>
                    <Button className='btn registrationPageBtn' onClick={() => navigate('/register')} >Registration Page</Button>
                </Row>
            )
        }
    }

    return (
        <div className="notFoundWrapper">
            <Row justify='center' >
                <Widget span={10}>
                    <h2 className='notFoundTitle'>Page Not Found</h2>
                    {renderBtn()}
                </Widget>
            </Row>
        </div>
    )
}

export default NotFound;