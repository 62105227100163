export const PerfixCookieNotDelete = "PerfixCookieNotDelete_"

export const Carriers = {
    1: 'UPS',
    2: 'FedEx',
    3: 'USPS'
}

export const CarrierIds = {
    'UPS': 1,
    'FedEx': 2,
    'USPS': 3
}

export const ShipmentStatus = {
    Created: 1,
    Processing: 2,
    Label_Created: 101,
    Failed: 102
}

export const ShipmentVoidStatus = {
    Void: 1,
    Partial_Void: 2,
}

export const LabelTypes = {
    1: 'PDF',
    2: 'HTML',
    3: 'ZPL',
    4: 'ELP',
    5: 'JPG',
    6: 'PNG',
    7: 'GIF'
}

export const UserRoles = {
    1: 'Company Admin',
    2: 'Power User',
    3: 'Normal User',
    4: 'Yamato Branch User',
    5: 'Super Admin'
}

export const UploadFileTypes = {
    1: 'UPS INVOICE'
}

export const CarrierServiceCode = {
    UPS: {
        US: {
            "GROUND": "03",
            "Next Day Air": "01"
        },
        CA: {
            "UPS Standard": "11"
        }
    }
}

export const ApplicationStatus = {
    RECIEVE: 'application_received',
    UNDER_REVIEW: 'under_review',
    REJECTED: 'rejected',
    ON_HOLD: 'on_hold',
    COMPLETE: 'complete'
}

export const AccountStatusIds = {
    1: 'Active',
    2: 'Locked',
    3: 'Disactive'
}


export const AccountStatus = {
    ACTIVE: 1,
    Locked: 2,
    Disactive: 3
}

export const DefaultPaymentTerms = {
    '2': 'Duty Collect',
    '3': 'All Prepaid',
}

export const PaymentTerms = {
    '1': 'All Collect',
    '2': 'Duty Collect',
    '3': 'All Prepaid',
    '4': 'Item Prepaid',
    '7': 'Item Collect',
    'c': 'Item Duties Collect'
}

export const DestinationCountries = {
    'JP': 'Japan',
    'HK': 'Hong Kong',
    'TW': 'Taiwan',
    'US': 'United States',
    'SG': 'Singapore'
}

