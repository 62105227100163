import React, { createContext, useReducer } from 'react';
import authReducer from '../reducers/authReducer';

export const AuthContext = createContext();

const initAuthState = {
    login: localStorage.getItem('access_token') ? true : false,
    user: null,
    token: localStorage.getItem('access_token') ?? '',
    permissions: []
};

export const AuthContextProvider = props => {
    const [state, dispatch] = useReducer(authReducer, initAuthState);
    return (
        <AuthContext.Provider value={[state, dispatch]}>
            {props.children}
        </AuthContext.Provider>
    );
};